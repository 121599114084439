// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// components
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';
import Trip from 'components/commonComponents/Trip';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'
import { Button } from '@mui/material';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { useDispatch, useSelector } from 'react-redux';
import Skelton from 'components/commonComponents/Skelton';
import Login from 'components/modals/Login';


const Trips = () => {
    const { tripsList, userDetails, currentLang } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const { tripType } = useParams();
    const [isLastPage, setIsLastPage] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingPage, setLoadingPage] = useState(false);
    const [show, setShow] = useState(false);
    const [searchtext, setSearchText] = useState('')
    const [pageData, setPageData]=useState('')


    const handlePageClick = () => {
        let curren = page
        setPage(curren + 1);
        fetchPaginateTrips(curren + 1)
    }

    const fetchTrips = async (page = 1) => {
        setLoading(true)
        if (tripType === 'featured') {
            const res = await fetchData(restAPIs.getFeaturedTrips(page, currentLang?.id));
            setLoading(false)
            if (res?.statusCode === 200) {
                dispatch({
                    type: 'tripsList',
                    value: res.featured
                });
                setIsLastPage(res.isLastPage)
                setPageData({ title: res.pageTitle, description: res.pageDesc })
            }
        }
        else {
            const res = await fetchData(restAPIs.getRecentPublicTrips(page, currentLang?.id));
            setLoading(false)
            if (res?.statusCode === 200) {
                dispatch({
                    type: 'tripsList',
                    value: res.recent
                });
                setIsLastPage(res.isLastPage)
                setPageData({ title: res.pageTitle, description: res.pageDesc })
            }
        }
    };

    const fetchPaginateTrips = async (page = 1) => {
        setLoadingPage(true)
        if (tripType === 'featured') {
            const res = await fetchData(restAPIs.getFeaturedTrips(page, currentLang?.id));
            setLoadingPage(false)
            if (res?.statusCode === 200) {
                dispatch({
                    type: 'tripsList',
                    value: tripsList.concat(res.featured)
                });
                setIsLastPage(res.isLastPage)

            }
        }
        else {
            const res = await fetchData(restAPIs.getRecentPublicTrips(page, currentLang?.id));
            setLoadingPage(false)
            if (res?.statusCode === 200) {
                dispatch({
                    type: 'tripsList',
                    value: tripsList.concat(res.recent)
                });
                setIsLastPage(res.isLastPage)
            }
        }
    };

    useEffect(() => {
        dispatch({
            type: 'tripsList',
            value: []
        });
        fetchTrips();
        setPageData('')
    }, [tripType, currentLang]);

    const handleOnClickTrip = (item) => {
        item.isPremium ? userDetails ? userDetails.subscription !== 0 ? navigate('/trip/' + item.tripId) : navigate('/subscribe') : setShow(true) : navigate('/trip/' + item.tripId)
    }

    const handleCloseModel = (value) => {
        setShow(false);
      };

    const handleSearchClick = () =>{
        console.log("searceh", searchtext);
    }

    const handleOnChangeSearch = (value) => {
        setSearchText(value)
    }
    return (
        <>
            <Banner
                title={pageData?.title}
                decription={pageData?.description}
                placeholder='Search for destination, places or trips'
                image={BannerImg}
                withSearch={false}
                handleOnClick={handleSearchClick}
                value={searchtext}
                handleOnChange={handleOnChangeSearch}
            />

            <div className='container mt-5'>
                <div className='d-flex flex-column mb-5'>
                    <div className='__common_grid_list'>
                        {isLoading ?
                            [...Array(3)].map((item, idx) => {
                                return (
                                    <Skelton key={idx}/>
                                )
                            })
                            :
                            tripsList && tripsList?.map((item, idx) => {
                                return (
                                    <Trip
                                        key={idx + item.tripId}
                                        title={item.tripName}
                                        para={item.description}
                                        image={item.coverImage}
                                        handleOnClick={() => handleOnClickTrip(item)}
                                        premium={item.isPremium}
                                        author={item?.author?.userName}
                                    />
                                )
                            })
                        }
                        {isLoadingPage ?
                        [...Array(2)].map((item, idx) => {
                            return (
                                <Skelton />
                            )
                        })
                        : ''}
                        
                    </div>                    
                    {isLoading ? '' :
                        <div className='__pagination' style={{ justifyContent: 'center' }}>
                            {isLastPage ? '' : <Button onClick={handlePageClick} className='_hand_btn'>{t('more')} </Button>}
                        </div>
                    }
                </div>
                <Download />
            </div>

            <Login
                open={show}
                onClose={handleCloseModel}
            />
        </>
    );
};

export default Trips;
