// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// components
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import Skelton from 'components/commonComponents/Skelton';

const BookView = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState('');
    const { bookId } = useParams();
    const { t } = useTranslation();

    const fetchBook = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.bookInfo(bookId, currentLang?.id))
        setData(res.books[0])
        setLoading(false);
    }
    useEffect(() => {
        fetchBook();
    }, [currentLang]);

    return (
        <>
            <Banner
                title={data?.name}
                // decription='awards'
                image={BannerImg}
                withSearch={false}
            />
            <div className='container mt-5' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                {
                    isLoading ?
                        <Skelton />
                        :
                        <>
                            <div className='row'>
                                <div className='col-md-3 mb-3'>
                                    <img src={data?.coverImage} alt={data?.name} width={'100%'} height={'340px'} style={{ objectFit: 'contain' }}/>
                                </div>
                                <div className='col-md-6 mb-3' style={{paddingLeft:'20px', paddingRight:'20px'}}>
                                    <h4 style={{ color: '#4ac3f5', margin: 0 }}>{data?.subjectName}</h4>
                                    <h1 className='__common_page_head mt-3 mb-3' style={{ margin: 0, fontSize: '3rem' }}>{data?.name}</h1>
                                    <p dangerouslySetInnerHTML={{ __html: data?.addinfo }} style={{ lineHeight: '2', fontSize: '17px' }}></p>
                                    <a className='__read_now' href={'https://alwaraq.net/books/' + data?.bookid} target='_blank' >{t('read_now')}</a>
                                </div>
                                <div className='col-md-3 text-center'>
                                    <img src={data?.authorImage} alt={data?.author} width={'100%'} height={'300px'} style={{ objectFit: 'cover', borderRadius:'15px' }}/>
                                    <h6 className='mt-3'>{data?.author}</h6>
                                </div>
                            </div>
                        </>
                }

                <Download />
            </div>
        </>
    );
};

export default BookView;
