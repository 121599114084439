// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { useSelector } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSearchParams } from 'react-router-dom';


const ChatFAQ = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [expanded, setExpanded] = useState('panel1');

    const [searchParams] = useSearchParams();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };

    const fetchFAQ = async () => {
        const res = await fetchData(restAPIs.getChatFAQ(searchParams.get('subjectId'), currentLang?.id, searchParams.get('subSubjectId')));
        if (res.statusCode === 200) {
            setData(res?.AiFAQ)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchFAQ();
    }, [currentLang]);


    return (
        <>
            <Banner
                title={'FAQ'}
                image={BannerImg}
                withSearch={false}
            />
            <div className='container mt-5'>
                <div className='row justify-content-center'>
                    <div className='col-md-9 mb-5'>
                        {/* <h4 style={{ color: '#4ac3f5', margin: 0 }}>{data?.tagline}</h4> */}
                        <h1 className='__common_page_head' style={{ color: '#439cc8', marginBottom: '30px' }}>Previously asked questions</h1>
                        {/* <p dangerouslySetInnerHTML={{ __html: data?.description }} style={{ lineHeight: '2', fontSize: '17px' }}></p> */}

                        <div className='mt-4' style={{padding:'10px'}} dir={currentLang?.id==1?'rtl':'ltr'}>
                            {data && data?.map((item, idx) => {
                                return (
                                    <Accordion className='mb-2' expanded={expanded === idx} onChange={handleChange(idx)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography>
                                                <b>{item?.name}</b>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography dangerouslySetInnerHTML={{ __html: item?.description }}>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                        </div>
                        <p style={{fontSize:'16px'}}>{data.length == 0 ? t('no_results_found') :''}</p>

                        
                    </div>
                </div>

                <Download />
            </div>
        </>
    );
};

export default ChatFAQ;
