// Core
import styled, { css } from 'styled-components';

export const StyledTrip = styled.div`
cursor: pointer;
position:relative;
img {
    width: 100%;
    height: 275px;
    object-fit: cover;
    border-radius: 27px;
    background: #eee;
    min-height: 275px;
    min-width: 100%;
    text-align: center;
}

h1 {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: bold;
    width: 100%;
    margin-top: 15px;
}
h4{
  border-bottom: 1px solid #7474744f;
  font-size: 15px;
    width: 100%;
    padding-bottom: 10px;
    color:#7f4d03;
}
p{
  font-size: 16px;
  color:#747474cf;
}
a{
  font-size: 16px;
  color: #0780bd !important;
  cursor: pointer;
}
.__premium{
    border-radius: 20px 0px;
    background: #e91e63;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 30px;
    right: -10px;
    padding: 4px 10px;
}
`;
