// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// components
import Download from 'components/commonComponents/DownloadApp';
import Trip from 'components/commonComponents/Trip';

// Styles
import './styles.scss';

// Assets
import { Avatar, Button as ButtonMUI, Stack } from '@mui/material';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { useDispatch, useSelector } from 'react-redux';
import Skelton from 'components/commonComponents/Skelton';
import Login from 'components/modals/Login';
import Slider from 'react-slick';
import Button from 'components/commonComponents/Button';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const HomePage = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [tripsList, setTrips] = useState([])
    const [sliderRef, setSliderRef] = useState('')
    const [sliderItem, setSliderItem] = useState('')
    const [clickable, setClickable] = useState(true);
    const [publicTripList, setPubTrips] = useState([]);
    const [pubSliderItem, setPubSliderItem] = useState('')
    const [articles, setArticles] = useState([])
    const [sliderRefArticle, setSliderRefArticle] = useState('')
    const [articleSliderItem, setArticleSliderItem] = useState('')
    const [sliderRefAward, setSliderRefAwards] = useState('')
    const [awards, setAwards] = useState([])
    const [fullData, setFullData] = useState(null)
    const [awardPage, setAwardPage] = useState(1)
    const [awardPageTitle, setAwardPageTitle] = useState('')
    const [awardPageDesc, setAwardPageDesc] = useState('')
    const [awardLastPage, setAwardLastPage] = useState(false)

    const onSliderPubChange = (newIndex) => {
        let items = publicTripList
        setPubSliderItem(items[newIndex])
    };

    const onSliderArticleChange = (newIndex) => {
        let items = articles
        setArticleSliderItem(items[newIndex])
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchDashboard();
        fetchAwards()
    }, [currentLang]);

    const handleOnClickTrip = (item) => {
        item.isPremium ? userDetails ? userDetails.subscription != 0 ? navigate('/trip/' + item.tripId) : navigate('/subscribe') : setShow(true) : navigate('/trip/' + item.tripId)
    }

    const handleCloseModel = (value) => {
        setShow(false);
    };
    const onSliderChange = (newIndex) => {
        setClickable(true);
        let items = tripsList
        setSliderItem(items[newIndex])
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        afterChange: onSliderChange,
        beforeChange: () => setClickable(false),
    };


    const slickPrev = () => {
        sliderRef.slickPrev();
    }

    const slickNext = () => {
        sliderRef.slickNext();
    }

    const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)

    const settingsLsg = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        autoplay: true,
        arrows: false,
        afterChange: onSliderPubChange,
        responsive: [
            {
                speed: 1000,
            }
        ]
    };

    const fetchDashboard = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getDashboard(currentLang?.id));
        if (res?.statusCode === 200) {
            setPubTrips(res.recent);
            setPubSliderItem(res.recent[0])
            setTrips(res.featured);
            setSliderItem(res.featured[0])
            setArticles(res.articleToday)
            setLoading(false)
            setFullData(res)
        }
    }

    const gotoFetured = () => {
        navigate('/trips/featured')
    }

    const gotoIBAwards = () => {
        navigate('/awards')
    }

    const gotoPublic = () => {
        navigate('/trips/public')
    }

    const settingsArticle = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        afterChange: onSliderArticleChange
    };
    const slickPrevArticle = () => {
        sliderRefArticle.slickPrev();
    }

    const slickNextArticle = () => {
        sliderRefArticle.slickNext();
    }

    const handleOnClickArticle = (item) => {
        item.isPremium ? userDetails ? userDetails.subscription != 0 ? navigate(`/article/${item.tripId}/${item.articleId}/`) : navigate('/subscribe') : setShow(true) : navigate(`/article/${item.tripId}/${item.articleId}/`)
    }

    const fetchAwards = async () => {
        const res = await fetchData(restAPIs.awardsList(currentLang?.id, null, null, 1));
        setAwards(res.items)
        setAwardPageTitle(res.pageTitle)
        setAwardPageDesc(res.pageDesc)
        setAwardLastPage(res.isLastPage)
    }

    const fetchAwardsPaginate = async () => {
        const res = await fetchData(restAPIs.awardsList(currentLang?.id, '', '', awardPage));
        let awardsLis = awards
        setAwards(awardsLis.concat(res.items))
    }

    const slickPrevAward = () => {
        sliderRefAward.slickPrev();
    }

    const slickNextAward = () => {
        sliderRefAward.slickNext();
        if (!awardLastPage) {
            let currenPage = awardPage + 1
            setAwardPage(currenPage)
            fetchAwardsPaginate()
        }
    }

    const settingsAwards = {
        dots: false,
        autoplay: false,
        infinite: false,
        slidesToShow: 7,
        slidesToScroll: 7,
        arrows: false,
        afterChange: onSliderArticleChange,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {

                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            }
        ]
    };


    return (
        <>
            {/* top banner */}
            <div className='__hm_slider_outer'>
                <Slider {...settings} style={{ height: '100%' }} ref={c => setSliderRef(c)}>
                    {tripsList && tripsList?.map((item, idx) => {
                        return (
                            isLoading ?
                                [...Array(3)].map((item, id) => {
                                    return (
                                        <div className='__hme_slider' key={id}>
                                            <Skelton />
                                        </div>

                                    )
                                })
                                :
                                <div key={item} >
                                    <div className='__hme_slider' style={{ backgroundImage: `url(${item.coverImage})` }} >
                                        <h1>{item.tripName}</h1>
                                        {/* <h3 style={{ color: '#fff' }} className='mt-2'>{item.description}</h3> */}
                                        <Button
                                            label={t('view_trip')}
                                            style={{ margin: 0, fontSize: '22px', marginTop: '20px', width: '300px', backgroundColor: '#0083bb', height: '54px' }}
                                            handleOnClick={() => clickable && handleOnClickTrip(sliderItem)}
                                        />
                                    </div>
                                </div>
                        )
                    })}
                </Slider>
                <div className='__gradient'></div>
                <button className="__button" onClick={() => slickPrev()} style={{ left: '5%' }}>
                    <ArrowRightAltIcon style={{ rotate: '180deg' }} sx={{ fontSize: 50 }} /> <span>{t('previous_trip')}</span>
                </button>
                <button className="__button" onClick={() => slickNext()} style={{ right: '5%' }}>
                    <span>{t('next_trip')}</span> <ArrowRightAltIcon sx={{ fontSize: 50 }} />
                </button>
            </div>
            {/* Explore Trips */}
            <div className='container mt-5'>
                <div className='d-flex flex-column mb-5'>
                    <h1 className='__common_page_head mb-1 text-center'>{fullData?.featuredTitle}</h1>
                    <p className='__sub_head text-center'>{fullData?.featuredDesc}</p>
                    <div className='__common_grid_list'>
                        {isLoading ?
                            [...Array(3)].map((item, idx) => {
                                return (
                                    <Skelton key={idx} />
                                )
                            })
                            :
                            tripsList && tripsList?.map((item, idx) => {
                                return (
                                    idx < 6 ? <Trip
                                        key={idx + item.tripId}
                                        title={item.tripName}
                                        para={item.description}
                                        image={item.coverImage}
                                        handleOnClick={() => handleOnClickTrip(item)}
                                        premium={item.isPremium}
                                        author={item?.author?.userName}
                                    /> : ''
                                )
                            })
                        }
                    </div>
                    <div className='__pagination' style={{ justifyContent: 'center' }}>
                        <ButtonMUI className='_hand_btn' style={{ width: '140px' }} onClick={gotoFetured}>{t('more')} </ButtonMUI>
                    </div>
                </div>

            </div>

            {/* timeline */}
            <div style={{ background: '#0083bb', padding: '40px 0', color: '#fff' }} className='mb-2'>
                <div className='container' style={{ position: 'relative' }}>
                    <h1 className='__common_page_head mb-1 text-center'>{awardPageTitle}</h1>
                    <p className='__sub_head text-center mb-5 '>{awardPageDesc}</p>
                    <div className='__award_lists'>
                        <button className="__award_button" onClick={() => slickPrevAward()} >
                            <ArrowBackIcon sx={{ fontSize: 22 }} />
                        </button>
                        <div style={{ width: 'calc(100% - 86px)', zIndex: 1 }}>
                            <Slider {...settingsAwards} style={{ height: '100%' }} ref={c => setSliderRefAwards(c)}>
                                {awards && awards.map((item, i) => {
                                    return (
                                        i % 2 == 0 ? <div className='__single_award' key={i}>
                                            <Avatar alt={item.authorName} src={item.authorImage} />
                                            <h1>{item.authorName}</h1>
                                            <div className='__round'></div>
                                            <div className='__dashed'></div>
                                            <div className='__year'>{item.year}</div>
                                            <div style={{ position: 'relative' }}>
                                                <img className='imgss' src={item.coverImage} alt={item.bookName} />
                                                <span className='__category'>{item.categoryName}</span>
                                            </div>
                                        </div> :
                                            <div className='__single_award' key={i}>
                                                <div style={{ position: 'relative' }}>
                                                    <img className='imgss' src={item.coverImage} alt={item.bookName} />
                                                    <span className='__category_bot'>{item.categoryName}</span>
                                                </div>
                                                <div className='__year'>{item.year}</div>
                                                <div className='__dashed'></div>
                                                <div className='__round'></div>
                                                <h1>{item.authorName}</h1>
                                                <Avatar alt={item.authorName} src={item.authorImage} />
                                            </div>
                                    )
                                })}
                            </Slider>
                        </div>
                        <button className="__award_button" onClick={() => slickNextAward()} >
                            <ArrowBackIcon sx={{ fontSize: 22 }} style={{ rotate: '180deg' }} />
                        </button>
                        <div className='__line'></div>
                    </div>
                    <div className='__pagination mt-5' style={{ justifyContent: 'center' }}>
                        <ButtonMUI className='_hand_btn' style={{ width: '140px', color: '#fff', borderColor: '#fff' }} onClick={gotoIBAwards}>{t('more')} </ButtonMUI>
                    </div>

                </div>

            </div>
            {/* Latest Articles */}
            <div className='container mt-5'>
                <h1 className='__common_page_head  mb-1 text-center'>{fullData?.articleTodayTitle}</h1>
                <p className='__sub_head text-center'>{fullData?.articleTodayDesc}</p>
                <div className='__article_slider_outer'>
                    <Slider {...settingsArticle} style={{ height: '100%' }} ref={c => setSliderRefArticle(c)}>
                        {articles.map((item, idx) => {
                            return (
                                <div className='__latest_article' key={idx}>
                                    <img src={item.coverImage} alt={item?.articleName} />
                                    <div className='d-flex flex-column align-items-center'>
                                        <h1>{item?.articleName}</h1>
                                        <p>{item?.location?.location}</p>
                                        <p style={{ width: '70%', textShadow: '0px 0px 10px #0003' }} className='text_wrap'>{item?.description}</p>
                                        <p>by {item?.author?.userName}</p>
                                    </div>
                                    <div className='__gradient'></div>
                                    <Button
                                        label='View Article'
                                        style={{ margin: 0, marginTop: '10px', backgroundColor: 'transparent', border: '1px solid #fff' }}
                                        handleOnClick={() => handleOnClickArticle(articleSliderItem)}
                                    />
                                </div>
                            )
                        })}
                    </Slider>

                    <button className="__button" onClick={() => slickPrevArticle()} style={{ left: '5%' }}>
                        <ArrowBackIcon sx={{ fontSize: 30 }} />
                    </button>
                    <button className="__button" onClick={() => slickNextArticle()} style={{ right: '5%' }}>
                        <ArrowBackIcon sx={{ fontSize: 30 }} style={{ rotate: '180deg' }} />
                    </button>
                </div>
            </div>

            {/* recent public trips */}
            <div className='container mt-2'>
                <div className='row mt-5'>
                    <h1 className='__common_page_head mb-1 text-center'>{fullData?.recentTripsTitle}</h1>
                    <p className='__sub_head text-center'>{fullData?.recentTripsDesc}</p>
                    <div className='__pop_sec'>
                        <div className='__left_set'>
                            <Slider
                                asNavFor={nav1}
                                ref={slider => (setNav2(slider))}
                                swipeToSlide={false}
                                focusOnSelect={true}
                                autoplay={true}
                                vertical={true}
                                slidesToShow={2}
                                arrows={false}
                            >
                                {publicTripList && publicTripList.map((item, idx) => {
                                    return (
                                        <div className='__sing_trip' key={idx}>
                                            <img src={item.coverImage} />
                                            <h1>{item.tripName}</h1>
                                            <p>{item.description && item.description.substring(0, 80)}</p>
                                            <div className='arrow-right'></div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                        <div className='__right_set'>
                            <Slider
                                asNavFor={nav2}
                                ref={slider => (setNav1(slider))}
                                {...settingsLsg}
                            >

                                {publicTripList && publicTripList.map((item, idx) => {
                                    return (


                                        <div key={idx} className='__detail_content_wrapper flex-column' style={{ marginTop: '0' }}>
                                            <img src={item.coverImage} alt={item?.tripName} style={{ width: '100%', height: '310px', objectFit: 'cover', borderRadius: '30px', marginBottom: '20px', objectPosition: 'top' }} />
                                            <div className='__main_content' style={{ maxHeight: '65vh' }}>
                                                <div className='d-flex justify-content-between align-items-center __on__mb'>
                                                    <div>
                                                        <h1 style={{ fontSize: '24px' }}>{item?.tripName}</h1>
                                                        <p style={{ fontSize: '16px', marginBottom: '5px' }}>{item?.displayDate}</p>
                                                    </div>
                                                    <Button
                                                        label={t('view_trip')}
                                                        style={{ margin: 0, fontSize: '20px', marginTop: '10px', width: '250px', backgroundColor: '#0083bb', height: '44px', marginRight: 0 }}
                                                        handleOnClick={() => handleOnClickTrip(pubSliderItem)}
                                                    />
                                                </div>
                                                <Stack direction="row" spacing={2} alignItems="center">
                                                    <Avatar alt={item?.author?.userName} src={item?.author?.profilePic} sx={{ width: 30, height: 30 }} />
                                                    <h6 style={{ margin: '0 16px' }}>{item?.author?.userName}</h6>
                                                </Stack>
                                                <div className='border-bottom mt-1'></div>
                                                <p className='__para'>{item?.description}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>

                        </div>
                    </div>
                    <div className='__pagination' style={{ justifyContent: 'center' }}>
                        <ButtonMUI className='_hand_btn' style={{ width: '140px' }} onClick={gotoPublic}>{t('more')} </ButtonMUI>
                    </div>
                </div>
            </div>

            {/* Download */}
            <div className='container'>
                <Download />
            </div>
            <Login open={show} onClose={handleCloseModel} />
        </>
    );
};

export default HomePage;
