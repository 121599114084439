import React from 'react';
import Skeleton from '@mui/material/Skeleton';

const Skelton = () => {
    return (
        <div style={{ width: '100%' }}>
            <Skeleton variant="rounded" width="99%" height={270} style={{borderRadius:'27px'}}/>
            <Skeleton width="80%" />
            <Skeleton />
            <Skeleton width="60%" />
        </div>
    );
};

export default Skelton;

