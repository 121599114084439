// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './styles.scss';

// Assets
import SearchIcon from '@mui/icons-material/Search';


const Banner = (props) => {
    const { title, decription, placeholder, image, handleOnClick, withSearch, handleOnChange, onKeyDown, value ,...rest } = props;
    const onChangeHandle = (e) => handleOnChange?.(e.target.value);
    return (
        <div className='__comm_banner_se'>
            <img src='https://ibnbatuta.ae/banner.webp' alt={title}/>
            <h1>{title}</h1>
            <p>{decription}</p>
            {
                withSearch ?
                    <div className='__input_sec'>
                        <input type="text" placeholder={placeholder} onChange={onChangeHandle} value={value} onKeyDown={onKeyDown}/>
                        <button onClick={handleOnClick}><SearchIcon fontSize="large" /></button>
                    </div>
                    : ''
            }
        </div>

    );
};

export default Banner;

Banner.propTypes = {
    title: PropTypes.string,
    decription: PropTypes.string,
    placeholder: PropTypes.string,
    image: PropTypes.string,
    withSearch: PropTypes.bool,
    handleOnClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    handleOnChange: PropTypes.func
};
