// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'
import { Button } from '@mui/material';
import Article from 'components/commonComponents/Article';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import Skelton from 'components/commonComponents/Skelton';



const Articles = () => {
    
    const { articlesList, currentLang } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const { tripId } = useParams();
    const [isLastPage, setIsLastPage] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingPage, setLoadingPage] = useState(false);
    const [totalArticles, setTotalArticles] = useState(0)

    const handleOnClickArticle = (id) => {
        navigate('/article/' + tripId + '/' + id);
    }

    const handlePageClick = () => {
        let curren = page
        setPage(curren + 1);
        fetchPaginateArticles(curren + 1)
    }

    const fetchArticles = async (page = 1) => {
        setLoading(true)
        const formData = new FormData();
        formData.append('tripId', tripId);
        formData.append('page', page);
        formData.append('contentLanguage', currentLang?.id)
        const res = await fetchData(restAPIs.getArticles(formData));
        setLoading(false)
        if (res?.statusCode === 200) {
            dispatch({
                type: 'articlesList',
                value: res.articles
            });
            setIsLastPage(res.isLastPage)
            setTotalArticles(res.total)
        }
    };

    const fetchPaginateArticles = async (page = 1) => {
        setLoadingPage(true)
        const formData = new FormData();
        formData.append('tripId', tripId);
        formData.append('page', page);
        formData.append('contentLanguage', currentLang?.id)
        const res = await fetchData(restAPIs.getArticles(formData));
        setLoadingPage(false)
        if (res?.statusCode === 200) {
            dispatch({
                type: 'articlesList',
                value: articlesList.concat(res.articles)
            });
            setIsLastPage(res.isLastPage)
        }
    }

    useEffect(() => {
        dispatch({
            type: 'articlesList',
            value: []
        });
        fetchArticles();
    }, [currentLang]);

    return (
        <>
            <Banner
                title='Explore Articles'
                decription='Check out the featured articles'
                placeholder=''
                image={BannerImg}
                withSearch={false}
            />

            <div className='container mt-5'>
            <h1 className='__common_page_head'>{articlesList && articlesList[0]?.tripName}({totalArticles && totalArticles})</h1>
                <div className='d-flex flex-column mb-5'>
                    <div className='__article-list'>
                        {isLoading ?
                            [...Array(3)].map((item, idx) => {
                                return (
                                    <Skelton />
                                )
                            })
                            :
                            articlesList && articlesList?.map((item, idx) => {
                                return (
                                    <Article
                                        title={item.articleName}
                                        para={item.displayDate}
                                        image={item.coverImage}
                                        handleOnClick={() => handleOnClickArticle(item.articleId)}
                                    />
                                )
                            })

                        }
                        {isLoadingPage ?
                            [...Array(2)].map((item, idx) => {
                                return (
                                    <Skelton />
                                )
                            })
                            : ''}
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    {isLoading ? '' :
                        <div className='__pagination' style={{ justifyContent: 'center' }}>
                            {isLastPage ? '' : <Button onClick={handlePageClick} className='_hand_btn'>{t('more')} </Button>}
                        </div>
                    }
                </div>
                <Download />
            </div>
        </>
    );
};

export default Articles;
