// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import InputField from 'components/commonComponents/InputField';
import Button from 'components/commonComponents/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Snackbar } from '@mui/material';

// Styles
import './styles.scss';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import ResetPassword from '../ResetPassword';

const Otp = (props) => {
  const { userDetails } = useSelector((store) => store.commonData);
  const { onClose, open, email, type, value } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [otp, setData] = useState('');
  const [otpTemp, setDataTemp] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })
  const [resetOpen, setResetOpen] = useState(false);

  const handleOnChange = (value) => {
    setData(value)
  };

  const handleSave = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('email', email)
    formData.append('otp', otp)
    formData.append('appId', '29')
    setDataTemp(otp)
    const res = type == 'email' ? await fetchData(restAPIs.changeEmail(formData)) : await fetchData(restAPIs.otpVerify(formData));
    if (res.statusCode === 200) {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);

      if (type == 'email') {
        let localDetails = JSON.parse(localStorage.getItem('userDetails'));
        localDetails.email = email
        dispatch({
          type: 'userDetails',
          value: localDetails
        });
        localStorage.setItem('userDetails', JSON.stringify(localDetails));
        handleClose();
      }

      if (type == 'signup') {
        let full = { ...value, ...res.user }
        dispatch({
          type: 'userDetails',
          value: full
        });
        localStorage.setItem('userDetails', JSON.stringify(full));
        localStorage.setItem('sessiontoken', value.sessionToken);
        handleClose();
      }

      if (type == 'login') {
        onClose()
        dispatch({
          type: 'userDetails',
          value: res.user
        });
        localStorage.setItem('userDetails', JSON.stringify(res.user));
        localStorage.setItem('sessiontoken', res.user.sessionToken);
      }
      if (type == 'forgot') {
        setResetOpen(true);
      }
      setData('');

    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    setIsLoading(false);
  };

  const handleClose = (event, reason) => {
    setData('');
    setErrorAlert({})
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const handleCloseReset = () => {
    setResetOpen(false);
    onClose();
  };


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        maxWidth={'sm'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          OTP
        </DialogTitle>
        <DialogContent>
          <p>An OTP is sent to your email for verification
            <br />Please check your email inbox or spam folders for the OTP email.</p>
          <div className='mt-2'>
            <OtpInput
              value={otp}
              onChange={handleOnChange}
              numInputs={4}
              isInputNum={true}
              className='_custom_input'
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
            <Button
              withLoader
              loading={isLoading}
              disabled={isLoading || otp.length != 4}
              handleOnClick={handleSave}
              label={t('submit')}
            />
          </div>
        </DialogActions>
        {errorAlert && errorAlert.open ? <Snackbar open={errorAlert.open} autoHideDuration={3000} >
          <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
            {errorAlert.msg}
          </Alert>
        </Snackbar> : ''}
        <ResetPassword onClose={handleCloseReset} open={resetOpen} email={email} otp={otpTemp} />
      </Dialog>
    </>
  );
};

export default Otp;
