// Core
import React from 'react';
import { useTranslation } from 'react-i18next';


// logo
import Appstore from 'assets/images/app_store.png'
import Playstore from 'assets/images/google_play.png'
import DownloadBack from 'assets/images/down_back.jpg'

// Styles
import './styles.scss';


const Download = () => {
  const { t } = useTranslation();

  return (
    <div className='__download'>
        <div className='__backgradient'></div>
        <img src='https://ibnbatuta.ae/down_back.jpg' alt="Download App" className='_backgrd'/>
        <h1>{t('download_app')}</h1>
        <p>{t('download_desc')}</p>
        <div>
            <a href="http://ibandroid.electronicvillage.org/" target="_blank"><img src='https://ibnbatuta.ae/google_play.png' alt="" /></a>
            <a href="http://ibios.electronicvillage.org/" target="_blank"><img src='https://ibnbatuta.ae/app_store.png' alt="" /></a>
        </div>
    </div>


  );
};

export default Download;
