// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'
import PremimImg from 'assets/images/premium-quality.png'
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';


import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import Skelton from 'components/commonComponents/Skelton';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import ReactPlayer from 'react-player';
import { Fab } from '@mui/material';

const AwardsAll = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const [isLoading, setLoading] = useState(false);
    const [awards, setData] = useState([]);
    const [photos, setPhotos] = useState([])
    const [videos, setVideos] = useState([])
    const [page, setPage] = useState('')
    const { type, typeId } = useParams();
    const [awardStatementTitle, setAwardStatementTitle] = useState('')
    const [awardStatementDesc, setAwardStatementDesc] = useState('')
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isAuthor, setAuthor] = useState(false)

    const fetchAwards = async () => {
        setLoading(true)
        const res = type === 'year' ? await fetchData(restAPIs.awardsList(currentLang?.id, null, typeId)) : await fetchData(restAPIs.awardsList(currentLang?.id, typeId, null))
        setData(res.items)
        setPage(res)
        if(res.photos)
        setPhotos(res.photos)
        if(res.videos)
        setVideos(res.videos)
        if(res.awardStatementTitle)
        setAwardStatementTitle(res.awardStatementTitle)
        if(res.awardStatementDesc)
        setAwardStatementDesc(res.awardStatementDesc)

        setLoading(false);
    }
    useEffect(() => {
        fetchAwards();
    }, [currentLang]);

    const handleBook = (bookId) => {
        navigate(`/book/${bookId}`)
    }
    
    const toggleImg = () =>{
        let isauth = isAuthor
        setAuthor(!isauth)
    }


    return (
        <>
            <Banner
                title={page?.pageTitle}
                // decription='awards'
                image={BannerImg}
                withSearch={false}
            />
            <div className='container mt-5' dir={currentLang?.id === 1?'rtl':'ltr'}>
                <div className='d-flex justify-content-between mb-3'>
                    <h1 className='__common_page_head'>{type !== 'year' ? awards[0]?.categoryName : awards[0]?.year}</h1>
                    <div>
                    <span>{isAuthor ? 'Books' : 'Awardees'}</span>
                    <Fab style={{ color: '#0284c5', margin: '0 5px', cursor: 'pointer' }} size='small' aria-label="Map" onClick={() => toggleImg()}>
                    <CameraswitchIcon />
                    </Fab>
                    </div>
                </div>
                {
                    isLoading ?
                        <div className='__awards_list'>
                            {[...Array(5)].map((item, idx) => {
                                return (
                                    <Skelton key={idx} />
                                )
                            })}
                        </div>

                        :
                            <>
                        <div className='__awards_list'>
                            {
                                awards && awards.map((item, i) => {
                                    return (
                                        <a className="singleBook" key={i} target='_blank' onClick={() => handleBook(item.bookId)}>
                                            <div style={{ position: 'relative', background: '#0001' }}>
                                            {isAuthor? <img src={item.authorImage} /> :<img src={item.coverImage} />}
                                                <div className="bookBind"></div>
                                                <span className="subJect">{item.categoryName}</span>
                                                {item?.winner===1 && <img  src={PremimImg} alt="" className="__premim_qlit" />}
                                            </div>
                                            <div className="singleBookInner">
                                                <h1>{item.bookName}</h1>
                                                <h5>{item.authorName}</h5>
                                                <h6>{item?.year}</h6>
                                            </div>
                                        </a>
                                    )
                                })
                            }
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>

                        <h1 className='__common_page_head mt-5'>{awardStatementTitle && awardStatementTitle}</h1>
                            <div>
                                <p dangerouslySetInnerHTML={{ __html: awardStatementDesc && awardStatementDesc }}></p>
                            </div>

                            <h1 className='__common_page_head mt-5'>{photos.length>0 ? t('photos') : ''}</h1>
                            <PhotoProvider>
                                <div className='__photos-list mt-3'>

                                    {photos && photos.map((item, idx) => {
                                        return (
                                            <PhotoView src={item.picture} >
                                                <img src={item.picture} alt="" key={idx} loading="lazy" />
                                            </PhotoView>
                                        )
                                    })}
                                    <div></div>
                                    <div></div>
                                </div>
                            </PhotoProvider>
                            <h1 className='__common_page_head mt-5'>{videos.length>0 ? t('videos') : ''}</h1>
                            <div className='__videos-list mt-3'>
                                {videos && videos.map((item, idx) => {
                                    return (
                                        <div className='player-wrapper'>
                                            <ReactPlayer url={item.url} controls={true}
                                                className='react-player'
                                                width='100%'
                                                height='100%'
                                            />
                                        </div>
                                    )
                                })}
                                <div></div>
                                <div></div>
                            </div>
                        </>
                }

                <Download />
            </div>
        </>
    );
};

export default AwardsAll;
