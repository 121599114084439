// Core
import React, { useState } from 'react';
// Styles
import './styles.scss';

import * as L from 'leaflet';
import { useEffect } from 'react';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useParams } from 'react-router-dom';
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';
import BannerImg from 'assets/images/banner.webp'
import ArticlesByLocationModal from 'components/modals/ArticlesByLocationModal';
import { useDispatch } from 'react-redux';
import { set } from 'lodash';
import { useTranslation } from 'react-i18next';


const Maps = () => {
  const { t } = useTranslation();
  const { tripId } = useParams();
  const [location, setLocation] = useState({})
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    fetchMap();
  });

  const fetchMap = async () => {
    const res = await fetchData(restAPIs.articlesByLoaction(tripId));
    const map = L.map("map").setView([43.63592499520541, 57.8759765625], 4);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '...'
    }).addTo(map);
    map.on('click', function (e) { console.log('map click!', e) });
    for (let i = 0; i < res.location.length; i++) {
      const element = res.location[i].location;
      let marker = L.marker([element.latitude, element.longitude], { opacity: 1, fillOpacity: 0.9, fillColor: '#ffc107' });
      marker.on('click', (e) => {
        L.DomEvent.stopPropagation(e);
        if (e) {
          dispatch({
            type: 'locationByArticle',
            value: res.location[i]
          });
          setLocation(res.location[i])
          setOpen(true)
        }
      });
      marker.addTo(map);
    }
  }

  const handleClose = () => {
    setOpen(false)
    dispatch({
      type: 'locationByArticle',
      value: {}
    });
  }


  return (
    <>
      <Banner
        title='Map'
        decription='Check out the articles by location'
        image={BannerImg}
        withSearch={false}
      />

      <div className='container mt-5'>
        <h1 className='__common_page_head'>{t('map')}</h1>
        <div className="fs-container mb-5">
          <div className="map-container" id="map" style={{ zIndex: 0 }}></div>
        </div>

        <ArticlesByLocationModal location={location} tripId={tripId} open={open} onClose={handleClose} />

        <Download />
      </div>
    </>
  );
};

export default Maps;
