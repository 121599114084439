// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { StyledArticle } from './style';


const Article = (props) => {
  const { title, para, image, handleOnClick, ...rest } = props;
  return (
    <StyledArticle {...rest} onClick={handleOnClick} >
      <img src={image} alt={title} />
      <div>
        <h1>{title}</h1>
        <p>{para}</p>
      </div>
    </StyledArticle>
  );
};

export default Article;

Article.propTypes = {
  title: PropTypes.string,
  para: PropTypes.string,
  image: PropTypes.string,
  handleOnClick: PropTypes.func
};
