// Core
import React, { useEffect, useState } from 'react';
// Styles
import './styles.scss';
import * as L from 'leaflet';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios'; // Add axios for API calls

import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import Button from 'components/commonComponents/Button';
import { useTranslation } from 'react-i18next';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


const MapPicker = (props) => {
  const { onClose, open, location } = props;
  const { t } = useTranslation();
  const [initialPosition, setInitialPosition] = useState([0, 0]);
  const [selectedPosition, setSelectedPosition] = useState([0, 0]);
  const [locationName, setLocationName] = useState('');


  useEffect(() => {
    if (location && location.latitude && location.longitude) {
      setInitialPosition([location.latitude, location.longitude]);
      setSelectedPosition([location.latitude, location.longitude, location.location || '']);
      setLocationName(location.location || '');
    }
  }, [location]);

  const fetchLocationName = async (lat, lng) => {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse`, {
        params: {
          lat,
          lon: lng,
          format: 'json',
          acceptLanguage: 'en'
        }
      });
      if (response.data && response.data.display_name) {
        return response.data.display_name; // Return the location name
      } else {
        return ''; // In case no location name is found
      }
    } catch (error) {
      console.error('Error fetching location name:', error);
      return ''; // Return empty string on error
    }
  };



  const Markers = () => {
    const map = useMapEvents({
      async click(e) {
        const lat = e.latlng.lat;
        const lng = e.latlng.lng;
        const location = await fetchLocationName(lat, lng); // Fetch the location name
        setSelectedPosition([lat, lng, location]); // Update selected position
      },
    })

    return (
      selectedPosition ?
        <Marker
          key={selectedPosition[0]}
          position={selectedPosition}
          interactive={false}
        />
        : null
    )
  }

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose(selectedPosition);
    }
  };


  return (
    <>

      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class_tabs'
        maxWidth={'md'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          {t('map')}
        </DialogTitle>
        <DialogContent>

          <MapContainer
            center={selectedPosition || initialPosition}
            zoom={2}
            style={{ height: "450px", width: "100%" }}
          >
            <Markers />
            <TileLayer
              attribution='...'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </MapContainer>
        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <Button
              handleOnClick={handleClose}
              label={t('ok')}
            />
          </div>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default MapPicker;

MapPicker.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  location: PropTypes.any
};
