// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import InputField from 'components/commonComponents/InputField';
import Button from 'components/commonComponents/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Checkbox, Skeleton, Snackbar } from '@mui/material';
// Styles
import './styles.scss';

import PublicIcon from '@mui/icons-material/Public';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PublicOffIcon from '@mui/icons-material/PublicOff';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import debounce from 'lodash/debounce';
import { useEffect } from 'react';

const ShareTrip = (props) => {
  const { userDetails } = useSelector((store) => store.commonData);
  const { onClose, open, tripId, isPublic } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dataSet, setData] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPublic, setIsLoadingPublic] = useState(false);
  const [isLoadingSelected, setIsLoadingSelected] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([])

  const handleSavePublic = async () => {
    setIsLoadingPublic(true);
    const formData = new FormData();
    formData.append('action', isPublic ? 'unpublish' : 'publish')
    formData.append('tripId', tripId)
    const res = await fetchData(restAPIs.shareTripWithPublic(formData));
    if (res.statusCode === 200) {
      setData([]);
      onClose(true, res.errormessage);
    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    setIsLoadingPublic(false);
  };

  const handleSaveSelected = async () => {
    setIsLoadingSelected(true);
    const formData = new FormData();
    formData.append('action', 'add')
    formData.append('tripId', tripId)
    for (let i = 0; i < selectedUsers.length; i++) {
      formData.append('shareUserId[]', selectedUsers[i])   
    }
    const res = await fetchData(restAPIs.shareTripWithUsers(formData));
    if (res.statusCode === 200) {
      setData([]);
      onClose(true, res.errormessage);
      setSearchQuery('')
    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    setIsLoadingSelected(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      setData([]);
      setErrorAlert({})
      setSearchQuery('')
      setSelectedUsers([])
      onClose();
    }
  };

  const handleChange = (_item) => {
    const currentIndex = selectedUsers?.findIndex((item) => item === _item);
    const _selectedUsers = [...selectedUsers];
    if (!_selectedUsers.includes(_item)) {
      _selectedUsers.push(_item);
    } else {
      _selectedUsers.splice(currentIndex, 1);
    }
    setSelectedUsers(_selectedUsers)
  };

  const debounceSearch = debounce(() => {
    fetchUsers();
  }, 500);

  useEffect(() => {
    debounceSearch();
    return () => {
      debounceSearch.cancel();
    };
  }, [searchQuery]);

  const fetchUsers = async () => {
    setIsLoading(true)
    const formData = new FormData();
    formData.append('searchPhrase', searchQuery)
    formData.append('tripId', tripId)
    const res = await fetchData(restAPIs.getUsers(formData));
    setData(res.users)
    let users = []
    res.users.forEach(element => {
      if(element.isShared)
      users.push(element.userId)
    });
    setSelectedUsers(users)
    setIsLoading(false)
  }


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        maxWidth={'sm'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          {t('share_trip')}
        </DialogTitle>
        <DialogContent>
          <>
            <div className='mt-1'>
              <p>{isPublic ? t('this_trip_is_shared_publicly') : t('lets_share_ur_tp_pub')}</p>
              <Button
                withLoader
                loading={isLoadingPublic}
                disabled={isLoadingPublic}
                handleOnClick={handleSavePublic}
                label={isPublic ? t('unshare') : t('share_publicly')}
                icon={isPublic ? <PublicOffIcon/> :<PublicIcon />}
              />
            </div>

            <div className='mt-2'>
              <h5>{t('share_with_selected_users')}</h5>
              <InputField
                onChange={setSearchQuery}
                value={searchQuery}
                placeholder={t('search_email_or_username')}
                type='text' 
                error={error.searchQuery}
              />
            </div>
            {isLoading ?
              <>
                {[...Array(3)].map((item, idx) => {
                  return (
                    <div className='d-flex align-items-center mt-2' style={{ width: '80%', margin:'0 auto' }}>
                      <Skeleton variant="rounded" width="40px" height="40px" style={{ borderRadius: '40px', marginRight: '15px' }} />
                      <div style={{ width: '100%' }}>
                        <Skeleton width="80%" />
                        <Skeleton />
                      </div>
                    </div>
                  )
                })}

              </>
              :
              <div className='__check_list'>
                {
                  dataSet && dataSet.map((item, idx) => {
                    return (
                      <div className='__user_wrap'>
                        <div className='d-flex'>
                          <img src={item.profilePic} alt="" />
                          <div>
                            <h1>{item.fullName}</h1>
                            <p>{item.email}</p>
                          </div>
                        </div>
                        <div>
                        <span style={{fontSize: '14px', color: 'green', marginLeft: '20px'}}>{item.isShared ? 'Already Shared' :''}</span>
                        <Checkbox
                          key={item.userId}
                          onChange={() => handleChange(item.userId)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          icon={<RadioButtonUncheckedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                          checked={selectedUsers.includes(item.userId) ? true : false}
                        />
                        </div>

                      </div>
                    )
                  })
                }
              </div>
            }
            <Button
              withLoader
              loading={isLoadingSelected}
              disabled={isLoadingSelected}
              handleOnClick={handleSaveSelected}
              label={t('share_with_selected_users')}
              className='mt-2'
            />
          </>
        </DialogContent>
        <DialogActions>
          <a onClick={() => handleClose(false)} className='__cancel'>{t('cancel')}</a>
        </DialogActions>
        {errorAlert && errorAlert.open ? <Snackbar open={errorAlert.open} autoHideDuration={3000} >
          <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
            {errorAlert.msg}
          </Alert>
        </Snackbar> : ''}
      </Dialog>
    </>
  );
};

export default ShareTrip;

ShareTrip.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  tripId: PropTypes.any,
  isPublic: PropTypes.bool,
};