// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { Button, CircularProgress } from '@mui/material';
import Highlight from 'components/commonComponents/Highlight';
import Article from 'components/commonComponents/Article';

const SearchInterMediate = () => {
    const { currentLang, searchTrip, searchArticle } = useSelector((store) => store.commonData);
    const { tripId } = useParams();
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [searchtext, setSearchText] = useState(searchArticle?.searchPhrase ? searchArticle?.searchPhrase : '')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [isLoadingPage, setLoadingPage] = useState(false);
    const [isLastPage, setIsLastPage] = useState(true);
    const [contentLanguage, setContentLanguage] = useState(searchArticle?.contentLanguage ? searchArticle?.contentLanguage : '')


    useEffect(() => {
        searchArticle?.searchPhrase ? setSearchText(searchArticle?.searchPhrase) : setSearchText(searchTrip?.searchPhrase)
        setContentLanguage(searchArticle?.contentLanguage)
        handleSubmit();
    }, [tripId]);

    const handleSubmit = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('language', currentLang?.lang);
        formData.append('searchPhrase', searchtext);
        formData.append('tripId', tripId);
        if (contentLanguage)
            formData.append('contentLanguage', contentLanguage);
        const res = await fetchData(restAPIs.searchArticles(formData));
        if (res.statusCode === 200) {
            dispatch({
                type: 'searchArticle',
                value: {
                    searchPhrase: searchtext,
                    articles: res.data,
                    contentLanguage : contentLanguage
                }
            });
            setData(res.data)
        }
        setLoading(false);

    }

    const handlePageClick = () => {
        let curren = page
        setPage(curren + 1);
        fetchPaginate(curren + 1)
    }

    const fetchPaginate = async (page) => {
        setLoadingPage(true);
        const formData = new FormData();
        formData.append('language', currentLang?.lang);
        formData.append('searchPhrase', searchtext);
        formData.append('tripId', tripId);
        formData.append('page', page)
        const res = await fetchData(restAPIs.searchArticles(formData));
        if (res.statusCode === 200) {
            dispatch({
                type: 'searchArticle',
                value: {
                    searchPhrase: searchtext,
                    articles: searchArticle?.articles.concat(res.data),
                    contentLanguage : contentLanguage
                }
            });
            setIsLastPage(res.isLastPage)
        }
        setLoadingPage(false);
    }

    const handleOnChangeSearch = (value) => {
        setSearchText(value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };
    const handleOnClickArticle = (id) => {
        navigate('/article/' + tripId + '/' + id+'?searchtext='+searchtext+'&contentLanguage='+contentLanguage);
    }


    return (
        <>
            <Banner
                title='Search'
                placeholder={'Search Articles here'}
                image={BannerImg}
                withSearch={true}
                handleOnClick={handleSubmit}
                value={searchtext}
                handleOnChange={handleOnChangeSearch}
                onKeyDown={handleKeyDown}
            />
            <div className='container mt-5'>
                {
                    isLoading ? <div className='text-center'>
                        <CircularProgress color="inherit" />
                    </div> :
                        <>
                            <div className='__article-list'>
                                {searchArticle && searchArticle?.articles?.map((item, idx) => {
                                    return (
                                        <Article
                                            title={<Highlight text={item?.articleName} highlight={searchtext} />}
                                            para={item.displayDate}
                                            image={item.coverImage}
                                            handleOnClick={() => handleOnClickArticle(item.articleId)}
                                        />

                                    )
                                })}
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            {isLoadingPage ? <div className='text-center'>
                                <CircularProgress color="inherit" />
                            </div> : ''}

                            {isLoading ? '' :
                                <div className='__pagination' style={{ justifyContent: 'center' }}>
                                    {isLastPage ? '' : <Button onClick={handlePageClick} className='_hand_btn'>{t('more')} </Button>}
                                </div>
                            }
                        </>
                }

                <Download />
            </div>
        </>
    );
};

export default SearchInterMediate;
