// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/commonComponents/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { useEffect } from 'react';
import { useRef } from 'react';
import ReactAudioPlayer from 'react-audio-player';

const AudioPlayerModal = (props) => {
  const { onClose, open, files } = props;
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState('')

  const audioRef = useRef(null)

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    // setIsPlaying(files[0]?.audio)
  },)

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        maxWidth={'sm'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          Audio
        </DialogTitle>
        <DialogContent>
          <div className='mt-2'>

            {files.map((file, index) => (
              <div key={index} style={{ width: '100%', marginBottom: '10px' }}>
                <h6>{file.audioTitle}</h6>
                <ReactAudioPlayer
                  src={file.audio}
                  controls
                  style={{ width: '100%' }}
                />
              </div>
            ))}

          </div>
        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <Button
              label={t('cancel')}
              handleOnClick={() => handleClose(false)}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AudioPlayerModal;

AudioPlayerModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  files: PropTypes.any,
};
