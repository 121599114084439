// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import Banner from 'components/commonComponents/Banner';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'
import DownloadBack from 'assets/images/download_back.webp'
import Playstore from 'assets/images/playstore.png'
import Appstore from 'assets/images/appstore.png'


import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import Button from 'components/commonComponents/Button';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from 'react-helmet';

const About = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [aboutData, setAboutData] = useState({});
    const [electronicData, setElectronicData] = useState({});
    const [founderData, setFounderData] = useState({});
    const [evApps, setEVApps] = useState([])
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const fetchAboutData = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getEVApps(currentLang?.id, 29));
        setAboutData(res.applications[0]);
        setLoading(false)
    };

    const fetchElectronicVillage = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getEVApps(currentLang?.id, 31));
        setElectronicData(res.applications[0]);
        setLoading(false)
    };

    const fetchFounder = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getFounderInfo(currentLang?.id));
        setFounderData(res.founder[0]);
        setLoading(false)
    };

    const fetchEVApps = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getEVApps(currentLang?.id));
        setEVApps(res.applications);
        setLoading(false)
    }

    useEffect(() => {
        fetchAboutData();
        fetchElectronicVillage();
        fetchFounder();
        fetchEVApps();
    }, [currentLang]);

    const handleApp = (id) => {
        navigate('/about/app/' + id)
    }
    const handleFounder = () => {
        navigate('/the-founder');
    }


    return (
        <>


            <Helmet>
                <title>About | Ibn Battuta | Explore the World</title>
                <meta name="title" content="About | Ibn Battuta | Explore the World" />
                <meta name="description" content="Ibn Batuta project showcases a vast collection of travelogues created by the world-famous travelers like Ibn Batuta, Ibn Jubair, Marco Polo etc. Ibn Batuta app is a modern tool for travelers to record the paths, findings and observation in the same way Ibn Batuta did but in a more intuitive and interactive manner. The tool accommodates the creation of an individualized register of traveling, not only of the destination but also of the route, the interactions and the experience." />

                <meta property="og:site_name" content="About | Ibn Battuta | Explore the World" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://ibnbatuta.ae/about" />
                <meta property="og:title" content="About | Ibn Battuta | Explore the World" />
                <meta property="og:description"
                    content="Ibn Batuta project showcases a vast collection of travelogues created by the world-famous travelers like Ibn Batuta, Ibn Jubair, Marco Polo etc. Ibn Batuta app is a modern tool for travelers to record the paths, findings and observation in the same way Ibn Batuta did but in a more intuitive and interactive manner. The tool accommodates the creation of an individualized register of traveling, not only of the destination but also of the route, the interactions and the experience." />
                <meta property="og:image" itemprop="image" content="https://ibnbatuta.ae/banner_image_ibn.png" />
                <meta property="og:image:secure_url" itemprop="image" content="https://ibnbatuta.ae/banner_image_ibn.png" />
                <meta property="og:image:type" content="image/png" />


                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://ibnbatuta.ae/about" />
                <meta property="twitter:title" content="About | Ibn Battuta | Explore the World" />
                <meta property="twitter:description"
                    content="Ibn Batuta project showcases a vast collection of travelogues created by the world-famous travelers like Ibn Batuta, Ibn Jubair, Marco Polo etc. Ibn Batuta app is a modern tool for travelers to record the paths, findings and observation in the same way Ibn Batuta did but in a more intuitive and interactive manner. The tool accommodates the creation of an individualized register of traveling, not only of the destination but also of the route, the interactions and the experience." />
                <meta property="twitter:image" content="https://ibnbatuta.ae/banner_image_ibn.png" />
            </Helmet>


            <Banner
                title={t('about_us')}
                // decription={t('about_us')}
                placeholder='Search for destination, places or trips'
                image={BannerImg}
                withSearch={false}
            />

            <div className='container mt-5' style={{ minHeight: '50vh' }}>
                {
                    isLoading ?
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        :
                        <>
                            <h1 className='__common_page_head'>{t('about_us')}</h1>
                            <div className='row'>
                                <div className='col-md-3 mb-5'>
                                    <img src={aboutData?.applicationIcon} alt={aboutData?.name} width={'100%'} height={'200px'} style={{ objectFit: 'contain' }} />
                                </div>
                                <div className='col-md-9 mb-5'>
                                    <h4 style={{ color: '#4ac3f5', margin: 0 }}>{aboutData?.tagline}</h4>
                                    <h1 className='__common_page_head' style={{ color: '#439cc8', margin: 0, fontSize: '3rem' }}>{aboutData?.name}</h1>
                                    <p dangerouslySetInnerHTML={{ __html: aboutData?.description }} style={{ lineHeight: '2', fontSize: '17px' }}></p>
                                </div>
                                <div className='col-md-9 mb-5'>
                                    <h4 style={{ color: '#4ac3f5', margin: 0 }}>{electronicData?.tagline}</h4>
                                    <h1 className='__common_page_head' style={{ color: '#8c5c3e', margin: 0, fontSize: '3rem' }}>{electronicData?.name}</h1>
                                    <p dangerouslySetInnerHTML={{ __html: electronicData?.description }} style={{ lineHeight: '2', fontSize: '17px', marginTop: '14px' }}></p>
                                </div>
                                <div className='col-md-3 mb-5'>
                                    <img src={electronicData?.applicationIcon} alt={electronicData?.name} width={'100%'} height={'200px'} style={{ objectFit: 'contain' }} />
                                </div>
                                <div className='col-md-3 mb-5'>
                                    <img src={founderData?.logo} alt={founderData?.name} width={'100%'} height={'200px'} style={{ objectFit: 'contain' }} />
                                </div>
                                <div className='col-md-9 mb-5'>
                                    <h4 style={{ color: '#4ac3f5', margin: 0 }}>{t('founder')}</h4>
                                    <h1 className='__common_page_head' style={{ color: '#8bc34a', margin: 0, fontSize: '3rem' }}>{founderData?.name}</h1>
                                    <p style={{ lineHeight: '2', fontSize: '17px' }} className="__inr_p" dangerouslySetInnerHTML={{ __html: founderData?.description }}></p>
                                    <Button
                                        label={t('more')}
                                        withLoader
                                        style={{ margin: '0 auto' }}
                                        handleOnClick={handleFounder}
                                    />
                                </div>
                            </div>
                            <h1 className='__common_page_head mt-4'>{t('other_ev_apps')}</h1>
                            <div className='__class_apps_set'>

                                {evApps && evApps.map((item, idx) => {
                                    return (
                                        <div className='__single_app'>
                                            <div className="d-flex flex-column justify-content-center align-items-center text-center" onClick={() => handleApp(item.appId)}>
                                                <img src={item?.applicationIcon} alt={item?.name} />
                                                <h1>{item?.name}</h1>
                                            </div>
                                            <div className="border-top pt-1 mt-1 text-center">
                                                <a href={item?.appStoreLink} target="_blank" ><img src={Appstore} alt="appstore" /></a>
                                                <a href={item?.googleStoreLink} target="_blank"><img src={Playstore} alt="playstore" /></a>
                                            </div>
                                        </div>
                                    )
                                })}



                            </div>
                        </>
                }
            </div>

        </>
    );
};

export default About;
