import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material"; // Assuming MUI for dialogs
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import EvLogo from "assets/images/chat/evLogo.png";
import Bot from "assets/images/chat/chat_bot.gif";
import Loader from "assets/images/chat/loader.gif";
import Speak from "assets/images/chat/speak.png";
import Speaking from "assets/images/chat/speaking.png";
import "./styles.scss";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AutoAwesome } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import Excerpt from "components/modals/Excerpt";
import { PlayArrow, StopOutlined } from "@mui/icons-material";

const responsiveVoice = window.responsiveVoice;

const ChatModel = (props) => {
    const { onClose, open } = props;
    const containerBody = useRef(null);
    const [isInitial, setIsInitial] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [text, setText] = useState("");
    const [messageList, setMessageList] = useState([]);
    const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
        useSpeechRecognition();
    const [isPlaying, setIsPlaying] = useState(false);

    
    const [isContextOpen, setIsContextOpen] = useState(false);
    const [context, setContext] = useState();

    useEffect(() => {
        if (!browserSupportsSpeechRecognition) {
            console.error("Browser does not support speech recognition.");
            return;
        }

        if (listening) {
            setText(transcript);
        }
    }, [listening, transcript, browserSupportsSpeechRecognition]);

    useEffect(() => {
        scrollToBottom();
    }, [messageList, isLoading]);

    const scrollToBottom = () => {
        if (containerBody.current) {
            containerBody.current.scrollTop = containerBody.current.scrollHeight;
        }
    };

    const submitText = async () => {
        if (!text.trim()) return;

        setIsInitial(true);
        setIsLoading(true);
        const userMessage = { type: "user", text };
        const newMessageList = [...messageList, userMessage];
        setMessageList(newMessageList);
        resetTranscript();
        const temp = text;
        setText("");

        try {
            const response = await fetch(
                `https://evai.in/chromadb/answer?query=${encodeURIComponent(
                    text
                )}&document_id=IB-AwardsList`
            );
            const res = await response.json();


            const aiMessage = {
                type: "response",
                text: "", // Start with empty text for animation
                question: temp,
                context: res.context,
            };

            setMessageList((prev) => [...prev, aiMessage]);
            simulateTyping(res.answer, () => {
                setIsLoading(false);
            });

            handleSpeak(res.answer);

        } catch (error) {
            console.error("Error fetching response:", error);
            setIsLoading(false);
        }

        setText("");
    };


    const handleShareAnswer = (answer) => {
        if (navigator.share) {
            navigator
                .share({ title: "IB.Ai Answer", text: answer })
                .catch((error) => console.error("Error sharing:", error));
        } else {
            alert("Sharing is not supported in your browser.");
        }
    };

    const handleStartListening = () => {
        SpeechRecognition.startListening({ continuous: true });
    };

    const handleStopListening = () => {
        SpeechRecognition.stopListening();
        resetTranscript();
    };

    const handleClose = () => {
        setIsInitial(false);
        setIsLoading(false);
        setText("");
        setMessageList([]);
        onClose();
    }

    const simulateTyping = (fullText, callback) => {
        let currentText = "";
        const interval = setInterval(() => {
            if (currentText.length < fullText.length) {
                currentText = fullText.slice(0, currentText.length + 1);
                setMessageList((prev) =>
                    prev.map((msg, i) =>
                        i === prev.length - 1 ? { ...msg, text: currentText } : msg
                    )
                );
            } else {
                clearInterval(interval);
                callback?.();
            }
        }, 30); // Typing speed in milliseconds
    };

    const handleSpeak = async (text) => {
        const cleanedText = text.replace(/[^\x20-\x7E]/g, '');
        const isEnglish = /^[\x20-\x7E]*$/.test(cleanedText);
        setIsPlaying(true);
        if (isEnglish) {
            responsiveVoice.speak(
                text,
                "UK English Male",
                {
                    onstart: () => {
                        console.log("Speech has started.");
                        setIsPlaying(true);
                    },
                    onend: () => {
                        console.log("Speech has ended.");
                        setIsPlaying(false);
                    },
                    onerror: (e) => {
                        console.error("An error occurred during speech synthesis:", e);
                        setIsPlaying(false);
                    }
                }
            );
            return;
        }

        responsiveVoice.speak(
            text,
            "Arabic Male",
            {
                onstart: () => {
                    console.log("Speech has started.");
                    setIsPlaying(true);
                },
                onend: () => {
                    console.log("Speech has ended.");
                    setIsPlaying(false);
                },
                onerror: (e) => {
                    console.error("An error occurred during speech synthesis:", e);
                    setIsPlaying(false);
                }
            }
        );
    };

    const handleCancel = () => {
        responsiveVoice.cancel();
        setIsPlaying(false);
    };

    const handleExcerpt = (context) => {
        setContext(context);
        setIsContextOpen(true);
        console.log("Context:", context);
    }

    return (
        <>
        <Dialog
            open={open}
            onClose={handleClose}
            className="__common_model_class_2"
            maxWidth={'100px'}
            fullWidth={true}
            disableEscapeKeyDown
            width="100%"
        >
            <DialogTitle id="alert-dialog-title">
                IB.Ai Chat
                <button onClick={handleClose} style={{ float: 'right', background: 'none', border: 'none' }}>
                <CloseIcon />
                </button>
            </DialogTitle>
            <DialogContent>
                <div className="___chat_container_wrapper">
                    <div className="__chat_display">
                        <div className="__container_body" ref={containerBody}>
                            {isInitial ? (
                                messageList.map((item, index) => (
                                    <div
                                        key={index}
                                        className={item.type === "user" ? "___use_resp_set user" : "___use_resp_set"}
                                        style={
                                            item.type === "user"
                                                ? { justifyContent: "end", flexDirection: "row-reverse", textAlign: "right" }
                                                : {}
                                        }
                                    >
                                        <div className="___user">
                                            {item.type === "user" ? (
                                                <AccountCircleIcon style={{ fontSize: '40px' }} />
                                            ) : (
                                                <img src={EvLogo} alt="EV Logo" />
                                            )}
                                        </div>
                                        <div className="___response">
                                            <h2>{item.type === "user" ? "You" : "IB.Ai"}</h2>
                                            <p>{item.text}</p>
                                            {item.type === "response" && (
                                                <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                                                    <button className="__share" onClick={() => handleShareAnswer(item.text)}>🔗 Share</button>
                                                    {isPlaying ? (
                                                        <button className="__share" onClick={handleCancel}>
                                                            <StopOutlined/>
                                                        </button>
                                                    ) : (
                                                        <button className="__share" onClick={() => handleSpeak(item.text)}>
                                                            <PlayArrow />
                                                        </button>
                                                    )}
                                                    <button className="__share" onClick={() => handleExcerpt(item)}>Knowledge Excerpt</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="__initial_loader">
                                    <img src={Bot} alt="Bot" />
                                    <span>
                                        Ask anything from <b>Awards</b>
                                    </span>
                                </div>
                            )}
                            {isLoading && (
                                <div className="__loading">
                                    <img src={Loader} alt="Loading" />
                                    Generating Answer...
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="___send_form">
                        <textarea
                            className="___text_area"
                            placeholder="Ask me anything!"
                            value={text}
                            onChange={(e) => setText(e.target.value)}
                            onKeyDown={(e) => e.key === "Enter" && submitText()}
                        />
                        <div className="__right_btn_actions">
                            {!listening ? (
                                <button onClick={handleStartListening} className="__micr">
                                    <img src={Speak} alt="Start Listening" />
                                </button>
                            ) : (
                                <button onClick={handleStopListening} className="__micr">
                                    <img src={Speaking} alt="Stop Listening" />
                                </button>
                            )}
                            <button onClick={submitText} disabled={!text.length}>
                            <AutoAwesome/> Ask
                            </button>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
        <Excerpt 
            open={isContextOpen}
            onClose={() => setIsContextOpen(false)}
            context={context}
        />
        </>
    );
};

export default ChatModel;
