// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/commonComponents/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useState } from 'react';

const FAQ = (props) => {
  const { currentLang } = useSelector((store) => store.commonData);
  const { onClose, open, subSubjectId, subjectId } = props;
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState('panel1');

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const fetchFAQ = async () => {
    const res = await fetchData(restAPIs.getChatFAQ(subjectId, currentLang?.id, subSubjectId?subSubjectId:''));
    if (res.statusCode === 200) {
      setData(res?.AiFAQ)
    }
    setLoading(false);
  }
  useEffect(() => {
    fetchFAQ();
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        maxWidth={'sm'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
        {t('frequently_asked_questions')}
        </DialogTitle>
        <DialogContent>
          <div className='mt-2' dir={currentLang?.id == 1 ? 'rtl' : 'ltr'}>
            {data && data?.map((item, idx) => {
              return (
                <Accordion className='mb-2' expanded={expanded === idx} onChange={handleChange(idx)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>
                      <b>{item?.name}</b>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography dangerouslySetInnerHTML={{ __html: item?.description }}>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div>
          <p style={{ fontSize: '16px' }}>{data.length == 0 ? t('no_questions_found') : ''}</p>
        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <Button
              label={t('cancel')}
              handleOnClick={() => handleClose(false)}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FAQ;

FAQ.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  subjectId: PropTypes.string,
  subSubjectId: PropTypes.string,
};
