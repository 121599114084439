// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/commonComponents/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Alert, Snackbar } from '@mui/material';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { useState } from 'react';
import InputField from 'components/commonComponents/InputField';
import { useDispatch } from 'react-redux';

const AddInvitation = (props) => {
  const { onClose, open } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [dataSet, setData] = useState({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { invitationkey } = dataSet;
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })

  const handleOnChange = (key) => (_value) => {
    if (_value) {
      setError({
        ...error,
        [key]: null
      });
    }
    setData({
      ...dataSet,
      [key]: _value
    });
  };


  const handleSave = async () => {
    const errors = {
      invitationkey: !invitationkey,
    };

    setError(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append('action', 'add')
    formData.append('appId', '29')
    formData.append('invitationCode', invitationkey)
    const res = await fetchData(restAPIs.subscriptionForm(formData));
    if (res.statusCode === 200) {
      if (res.isPremium) {
        setData({});
        setError({});
        setErrorAlert({ msg: "Subscribed successfully. !", key: Math.random(), open: true, severity: 'success' });
        setTimeout(() => {
          setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
        }, 3000);
        dispatch({
          type: 'userDetails',
          value: res
        });
        localStorage.setItem('userDetails', JSON.stringify(res));
        onClose();
      } else {
        setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
        setTimeout(() => {
          setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
        }, 3000);
      }
    }
    setIsLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        maxWidth={'sm'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
        {t('add_invitation_key')}
        </DialogTitle>
        <DialogContent>
        <div className='mt-2'>
            <InputField
              onChange={handleOnChange('invitationkey')}
              value={invitationkey}
              placeholder='Enter the Key'
              type='text'
              label='Key'
              error={error.invitationkey}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
            <Button
              withLoader
              loading={isLoading}
              disabled={isLoading}
              handleOnClick={handleSave}
              label={t('submit')}
            />
          </div>
        </DialogActions>
        {errorAlert && errorAlert.open ? <Snackbar open={errorAlert.open} autoHideDuration={3000} >
          <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
            {errorAlert.msg}
          </Alert>
        </Snackbar> : ''}
      </Dialog>
    </>
  );
};

export default AddInvitation;

AddInvitation.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
