// Core
import React, { useState } from 'react';
// Styles
import './styles.scss';

import { useEffect } from 'react';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';
import BannerImg from 'assets/images/banner.webp'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Skelton from 'components/commonComponents/Skelton';


const HealthCheck = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [scanId, setScanId] = useState('')
  const { userDetails, currentLang } = useSelector((store) => store.commonData);

  const getScanId = async () => {
    setLoading(true)
    let formData = new FormData()
    formData.append('appId', '29')
    formData.append('language', currentLang?.id)
    formData.append('action', 'scanId')

    const res = await fetchData(restAPIs.healthScanFedo(formData))
    console.log(":rs", res?.scanId);
    setScanId(res?.scanId)
    setLoading(false)
  }

  useEffect(() => {
    getScanId();
  }, [])



  return (
    <>
      <Banner
        title='Health Check'
        decription=''
        image={BannerImg}
        withSearch={false}
      />

      <div className='container mt-5'>
        <div className="mb-5">
          {loading ? <Skelton /> : <iframe style={{ width: '100%', height: '100vh' }} allow="camera; microphone" src={`https://fedo.ai/products/vitals/webapp/vitals?orgId=161&custId=${userDetails?.userid}&userId=781&tenantId=161&clientId=electronicvillage&scanId=${scanId && scanId}`} frameborder="0"></iframe>}
        </div>
        <Download />
      </div>
    </>
  );
};

export default HealthCheck;
