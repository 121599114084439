// Core
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const Excerpt = (props) => {
  const { onClose, open, context } = props;

  useEffect(() => {
    console.log('ssssssssssscontext', context);
  }, [context]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class'
      >
        <DialogTitle>Response Sources</DialogTitle>
        <DialogContent className='__common_model_content'>
          <div className='____common_model_class_desc'>
            {context?.context?.slice(0, 5).map((item, index) => {
              return (
                <div className='__single_context' key={index}>
                  <h1>Section {index + 1}</h1>
                  <p>{item?.page_content}</p>
                </div>
              );
            })}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Excerpt;

Excerpt.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  context: PropTypes.any,
};
