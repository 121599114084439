// Core
import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
// Components
import InputField from 'components/commonComponents/InputField';
import Button from 'components/commonComponents/Button';
// Styles
import './styles.scss';
// Assets
import DownloadBack from 'assets/images/download_back.webp'
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// Others
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useTranslation } from 'react-i18next';

import {
  LoginSocialGoogle,
  LoginSocialFacebook,
} from 'reactjs-social-login'
import { FACEBOOK_ID, GOOGLE_ID } from 'config/constants';
import { Alert, Snackbar } from '@mui/material';

import validator from 'validator';
import Otp from '../Otp';
import ForgotPassword from '../ForgotPassword';

const REDIRECT_URI = window.location.href;

const Login = (props) => {
  const { onClose, open } = props;
  const { currentLang, userDetails } = useSelector((store) => store.commonData);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [signupData, setSignupData] = useState({});
  const [signupLoading, setSignupLoading] = useState(false);
  const [signupError, setSignupError] = useState('');
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false })
  const [loginEye, setLoginEye] = useState(true);
  const [islogin, setIsLogin] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [profile, setProfile] = useState(null)
  const [openOtp, setOpenOtp] = useState(false);
  const [response, setSignupResp] = useState({});
  const [openOtpLogin, setOpenOtpLogin] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [signupEye, setSignupEye] = useState(true)
  const [signupEmail, setSignupEmail] = useState('')

  const { email = '', password = '' } = data;
  const { name, signup_email = '', signup_password = '' } = signupData;

  const handleOnChange = (key) => (value) => {
    setError({
      ...error,
      [key]: null
    });
    setData({
      ...data,
      [key]: value
    });
  };

  const handleOnChangeSignUp = (key) => (value) => {
    setSignupError({
      ...signupError,
      [key]: null
    });
    setSignupData({
      ...signupData,
      [key]: value
    });
  };

  const handleLoginIn = async () => {
    const errors = {
      email: !validator.isEmail(email) && 'Enter valid email',
      password: !password && 'You must enter a value'
    };
    setError(errors);
    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    const formData = new FormData();
    formData.append('email', data.email.toLowerCase())
    formData.append('password', btoa(unescape(encodeURIComponent(data.password))))
    formData.append('appid', '29')
    setLoading(true);
    const res = await fetchData(restAPIs.login(formData));
    if (res.statusCode === 200) {
      dispatch({
        type: 'userDetails',
        value: res.userinfo
      });
      localStorage.setItem('userDetails', JSON.stringify(res.userinfo));
      localStorage.setItem('sessiontoken', res.userinfo.sessionToken);
      handleClose();
    }
    else if (res.statusCode === 100) {
      setOpenOtpLogin(true);
      // handleClose();
    }
    else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false });
      }, 3000);
    }
    setLoading(false);
  };

  const handleSocialLogin = async (user, auth) => {
    const formData = new FormData();
    formData.append('fullname', user.name)
    formData.append('email', user.email)
    formData.append('auth', auth.toString())
    formData.append('language', currentLang?.id)
    formData.append('appid', '29')
    // setLoading(true);
    const res = await fetchData(restAPIs.signUp(formData));
    if (res.statusCode === 200) {
      dispatch({
        type: 'userDetails',
        value: res.user
      });
      localStorage.setItem('userDetails', JSON.stringify(res.user));
      localStorage.setItem('sessiontoken', res.user.sessionToken);
      handleClose();
    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false });
      }, 3000);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLoginIn();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const loginEyeClick = () => {
    setLoginEye(!loginEye);
  }

  const signupEyeClick = () =>{
    setSignupEye(!signupEye)
  }

  const handleIsLoginClick = () => {
    setIsLogin(!islogin);
  }

  const onLoginStart = useCallback(() => {
    // alert('login start')
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null)
    alert('logout success')
  }, []);

  const handleSignUp = async () => {
    const errors = {
      name: !name && 'You must enter a value',
      signup_email: !validator.isEmail(signup_email) && 'Enter valid email',
      signup_password: !signup_password && 'You must enter a value'
    };
    setSignupError(errors);
    const isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    const formData = new FormData();
    formData.append('fullname', signupData.name)
    formData.append('email', signupData.signup_email)
    formData.append('password', btoa(unescape(encodeURIComponent(signupData.signup_password))))
    formData.append('auth', '0')
    formData.append('language', currentLang?.id)
    formData.append('appid', '29')
    setSignupLoading(true);
    setSignupEmail(signupData.signup_email)
    const res = await fetchData(restAPIs.signUp(formData));
    if (res.statusCode === 200) {
      setSignupResp(res.user)
      setOpenOtp(true)
      setSignupData({})
    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false });
      }, 3000);
    }
    setSignupLoading(false);
  }

  const handleCloseOtp = () => {
    setOpenOtp(false);
    if(userDetails)
    handleClose()
  };

  const handleCloseOtpLogin = () => {
    setOpenOtpLogin(false);
  };

  const handleOpenForgotPassword = () => {
    setOpenForgot(true);
  };
  const handleCloseForgot = () => {
    setOpenForgot(false);
  };


  return (
    <Dialog onClose={handleClose} className='__model_class' open={open} maxWidth={false} >
      <div className='__log_wrap'>
        <img src='https://ibnbatuta.ae/down_back.jpg' alt="" />
        {islogin ?
        
          <div className="__login_form">
            {errorAlert && errorAlert.open ?
              <Alert severity="error" sx={{ width: '100%' }}>
                {errorAlert.msg}
              </Alert>
              : ''}
            <InputField
              onChange={handleOnChange('email')}
              value={email}
              type="email"
              placeholder={t('email')}
              label={t('email')}
              error={error.email}
              onKeyDown={handleKeyDown}
            />
            <div className='mt-4'>
              <InputField
                onChange={handleOnChange('password')}
                value={password}
                placeholder={t('password')}
                type={loginEye ? 'password' : 'text'}
                label={t('password')}
                error={error.password}
                onKeyDown={handleKeyDown}
                onIconClick={loginEyeClick}
                icon={loginEye ? <VisibilityIcon /> : <VisibilityOffIcon />}
              />
            </div>
            <a style={{ cursor: 'pointer', marginTop: '5px' }} onClick={handleOpenForgotPassword}>{t('forgot_password')}</a>
            <ForgotPassword open={openForgot} onClose={handleCloseForgot} />
            <Button
              withLoader
              loading={loading}
              disabled={loading}
              handleOnClick={handleLoginIn}
              label={t('login')}
              style={{ width: '100%' }}
              className='mt-4'
              icon={<EastOutlinedIcon />}
            />
            <Otp open={openOtpLogin} onClose={handleCloseOtpLogin} email={email} type={'login'} />

            <p className="d-flex align-items-center mt-4" style={{ marginBottom: '0px' }}>{t('not_a_member')}
              <a
                style={
                  {
                    margin: '0 10px',
                    color: '#4ac3f5',
                    cursor: 'pointer'
                  }
                }
                onClick={handleIsLoginClick}
              >{t('sign_up')} </a>
            </p>
            <div className='__or_sec'>
              <label>{t('or')}</label>
            </div>
            <div className='__social_log_sec'>
              <button>
                <LoginSocialGoogle
                  redirect_uri={REDIRECT_URI}
                  scope="openid profile email"
                  discoveryDocs="claims_supported"
                  access_type="offline"
                  client_id={GOOGLE_ID}
                  onLoginStart={onLoginStart}
                  onResolve={({ provider, data }) => {
                    setProfile(data)
                    handleSocialLogin(data, 2)
                  }}
                  onReject={(err) => {
                    console.log(err)
                  }}
                >
                  <GoogleIcon /> {t('google')}
                </LoginSocialGoogle>
              </button>
              <button style={{ background: '#507CC0' }}>
                <LoginSocialFacebook
                  fieldsProfile={
                    'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                  }
                  redirect_uri={REDIRECT_URI}
                  appId={FACEBOOK_ID}
                  onLoginStart={onLoginStart}
                  onResolve={({ data }) => {
                    setProfile(data)
                    handleSocialLogin(data, 1)
                  }}
                  onReject={(err) => {
                    console.log(err)
                  }}
                >
                  <FacebookOutlinedIcon /> {t('facebook')}
                </LoginSocialFacebook>
              </button>
            </div>
            <h6 className='text-center mt-3'>{t('ev_account_can_be')}</h6>
            
          </div> :
          <div className="__login_form">
            {errorAlert && errorAlert.open ?
              <Alert severity="error" sx={{ width: '100%' }}>
                {errorAlert.msg}
              </Alert>
              : ''}
            <div className='mb-4'>
              <InputField
                onChange={handleOnChangeSignUp('name')}
                value={name}
                type="text"
                placeholder={t('name')}
                label={t('name')}
                error={signupError.name}
              />
            </div>
            <div className='mb-4'>
              <InputField
                onChange={handleOnChangeSignUp('signup_email')}
                value={signup_email}
                type="email"
                placeholder={t('email')}
                label={t('email')}
                error={signupError.signup_email}
              />
            </div>
            <div>
              <InputField
                onChange={handleOnChangeSignUp('signup_password')}
                value={signup_password}
                placeholder={t('password')}
                type={signupEye ? 'password' : 'text'}
                label={t('password')}
                error={signupError.signup_password}
                onIconClick={signupEyeClick}
                icon={signupEye ? <VisibilityIcon /> : <VisibilityOffIcon />}
              />
            </div>
            <Button
              withLoader
              loading={signupLoading}
              disabled={signupLoading}
              handleOnClick={handleSignUp}
              label={t('sign_up')}
              style={{ width: '100%' }}
              className='mt-3'
              icon={<EastOutlinedIcon />}
            />
            <Otp open={openOtp} onClose={handleCloseOtp} email={signupEmail} type={'signup'} value={response} />
            <p className="d-flex align-items-center mt-3" style={{ marginBottom: '0px' }}>Already a member ?
              <a
                style={
                  {
                    margin: '0 10px',
                    color: '#4ac3f5',
                    cursor: 'pointer'
                  }
                }
                onClick={handleIsLoginClick}
              >{t('login')} </a>
            </p>
            <div className='__or_sec'>
              <label>{t('or')}</label>
            </div>
            <div className='__social_log_sec'>
              <button>
                <LoginSocialGoogle
                  isOnlyGetToken
                  client_id={GOOGLE_ID}
                  onLoginStart={onLoginStart}
                  onResolve={({ provider, data }) => {
                    setProfile(data)
                  }}
                  onReject={(err) => {
                    console.log(err)
                  }}
                >
                  <GoogleIcon /> {t('google')}
                </LoginSocialGoogle>
              </button>
              <button style={{ background: '#507CC0' }}>

                <LoginSocialFacebook
                  fieldsProfile={
                    'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                  }
                  redirect_uri={REDIRECT_URI}
                  appId={FACEBOOK_ID}
                  onLoginStart={onLoginStart}
                  onResolve={({ data }) => {
                    setProfile(data)
                    handleSocialLogin(data, 1)
                  }}
                  onReject={(err) => {
                    console.log(err)
                  }}
                >
                  <FacebookOutlinedIcon /> {t('facebook')}
                </LoginSocialFacebook>
              </button>
            </div>
            <h6 className='text-center mt-3'>{t('ev_account_can_be')}</h6>
          </div>
        }
      </div>
    </Dialog>
  );
};

export default Login;
Login.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
