// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import InputField from 'components/commonComponents/InputField';
import Button from 'components/commonComponents/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Snackbar } from '@mui/material';

// Styles
import './styles.scss';


const ChangePassword = (props) => {
  const { onClose, open } = props;
  const { t } = useTranslation();
  const [passwordSet, setPasswords] = useState({});
  const [errorPswd, setErrorPswd] = useState({});
  const [isLoadingPswd, setIsLoadingPswd] = useState(false);
  const { password, newPassword, confirmPassword } = passwordSet;
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity : 'error' })

  const handleOnChangePassword = (key) => (_value) => {
    if (_value) {
      setErrorPswd({
        ...errorPswd,
        [key]: null
      });
    }
    setPasswords({
      ...passwordSet,
      [key]: _value
    });
  };


  const handlePasswordSave = async () => {
    const errors = {
      password: !password,
      newPassword: !newPassword,
      confirmPassword: !confirmPassword
    };

    setErrorPswd(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (newPassword !== confirmPassword) {
      setErrorPswd((prev) => ({ ...prev, confirmPassword: 'Passwords must be same' }));
      isFormValid = false;
    }
    if (!isFormValid) return;
    setIsLoadingPswd(true);
    const formData = new FormData();
    formData.append('oldPassword', btoa(unescape(encodeURIComponent(password))))
    formData.append('newPassword', btoa(unescape(encodeURIComponent(newPassword))))
    formData.append('appid', '29')
    const res = await fetchData(restAPIs.changePassword(formData));
    if (res.statusCode === 200) {
      setPasswords({});
      setErrorPswd({});
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity : 'success' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity : '' });
      }, 3000);
      onClose();

    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity : 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity : '' });
      }, 3000);
    }
    setIsLoadingPswd(false);
  };

  const handleClose = () => {
    setPasswords({});
    setErrorPswd({});
    setErrorAlert({})
    onClose();
  };


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
        {t('change_password')}
        </DialogTitle>
        <DialogContent>
          <div className='mt-2'>
            <InputField
              onChange={handleOnChangePassword('password')}
              value={password}
              placeholder={t('password')}
              type='text'
              label={t('current_password')}
              error={errorPswd.password}
            />
          </div>
          <div className='mt-4'>
            <InputField
              onChange={handleOnChangePassword('newPassword')}
              value={newPassword}
              placeholder={t('password')}
              type='text'
              label={t('new_password')}
              error={errorPswd.newPassword}
            />
          </div>
          <div className='mt-4'>
            <InputField
              onChange={handleOnChangePassword('confirmPassword')}
              value={confirmPassword}
              placeholder={t('password')}
              type='text'
              label={t('confirm_password')}
              error={errorPswd.confirmPassword}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
            <Button
              withLoader
              loading={isLoadingPswd}
              disabled={isLoadingPswd}
              handleOnClick={handlePasswordSave}
              label={t('submit')}
            />
          </div>
        </DialogActions>
        {errorAlert && errorAlert.open ? <Snackbar open={errorAlert.open} autoHideDuration={3000} >
          <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
            {errorAlert.msg}
          </Alert>
        </Snackbar> : ''}
      </Dialog>
    </>
  );
};

export default ChangePassword;

ChangePassword.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
