const initialState = {
  userDetails: null,
  tripsList:[],
  articlesList:[],
  locationByArticle:{},
  locationByBook:{},
  currentLang: { lang: 'en', id: 2 },
  searchTrip:{},
  searchArticle:{},
  articlesList_Trip:[],
  currentTripId:null
};
export default initialState;
