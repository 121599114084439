// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import InputField from 'components/commonComponents/InputField';
import Button from 'components/commonComponents/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Snackbar } from '@mui/material';

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import validator from 'validator';
import Otp from '../Otp';

const ChangeEmail = (props) => {
  const { onClose, open } = props;
  const { t } = useTranslation();
  const [dataSet, setData] = useState({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { email } = dataSet;
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })
  const [openOtp, setOpenOtp] =  useState(false);


  const handleOnChange = (key) => (_value) => {
    if (_value) {
      setError({
        ...error,
        [key]: null
      });
    }
    setData({
      ...dataSet,
      [key]: _value
    });
  };


  const handleSave = async () => {
    const errors = {
      email: !validator.isEmail(email) && 'Enter valid email',
    };

    setError(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append('email', email)
    formData.append('appId', '29')
    const res = await fetchData(restAPIs.changeEmailOTP(formData));
    if (res.statusCode === 200) {
      setError({});
      setOpenOtp(true);
      // onClose();
    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    setIsLoading(false);
  };

  const handleClose = (event, reason) => {
    setData({});
    setError({});
    setErrorAlert({})

    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const handleCloseOtp = () => {
    setOpenOtp(false);
};

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        maxWidth={'sm'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
        {t('change_email')}
        </DialogTitle>
        <DialogContent>
          <div className='mt-2'>
            <InputField
              onChange={handleOnChange('email')}
              value={email}
              placeholder={t('enter_your_email_address')}
              type='email'
              label={t('email')}
              error={error.email}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
            <Button
              withLoader
              loading={isLoading}
              disabled={isLoading}
              handleOnClick={handleSave}
              label={t('submit')}
            />
          </div>
        </DialogActions>
        {errorAlert && errorAlert.open ? <Snackbar open={errorAlert.open} autoHideDuration={3000} >
          <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
            {errorAlert.msg}
          </Alert>
        </Snackbar> : ''}
        <Otp open={openOtp} onClose={handleCloseOtp} email={email} type={'email'}/>
      </Dialog>
    </>
  );
};

export default ChangeEmail;

ChangeEmail.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
