// Core
import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import Login from 'components/modals/Login';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useEffect } from 'react';

import BannerImg from 'assets/images/banner.webp'
import Button from 'components/commonComponents/Button';

// icons
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import Banner from 'components/commonComponents/Banner';
import Download from 'components/commonComponents/DownloadApp';
import Skelton from 'components/commonComponents/Skelton';
import validator from 'validator';
import InputField from 'components/commonComponents/InputField';

import { Alert, CircularProgress, Snackbar } from '@mui/material';
import EtisalatTerms from 'components/modals/EtisalatTerms';

const Etisalat = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const [data, setData] = useState('');
  const { subId } = useParams();
  const [duration, setDuration] = useState('')
  const [terms, setTerms] = useState('')
  const [encryptedPhone, setEncryptedPhone] = useState('')
  const [orderId, setOrderId] = useState('')
  const [txnId, setTxnId] = useState('')
  const [packageId, setPackageId] = useState('')
  const [tokenGenerated, setTokenGenerated] = useState('')
  const [encryptedPIN, setEncryptedPIN] = useState('')

  const [isLoading, setLoading] = useState(false);
  const [phoneData, setPhoneData] = useState('');
  const { phone } = phoneData;
  const [errorPhone, setErrorPhone] = useState('');
  const [inputSend, setInputSend] = useState(false);

  const [isLoadingPIN, setLoadingPIN] = useState(false);
  const [pinData, setPINData] = useState('');
  const { pin } = pinData;
  const [errorPIN, setErrorPIN] = useState('');

  const [isLoadingResendPIN, setLoadingResendPIN] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [openTerms, setOpenTerms] = useState(false)

  const [subDescription, setSubDescrip] = useState('');

  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })

  const [searchParams] = useSearchParams();
  const [referralCode, setreferralCode] = useState('')
  const [discountCode, setdiscountCode] = useState('')

  const fetchSubscriptions = async () => {
    setLoading(true)
    const formData = new FormData();
    formData.append('language', currentLang?.id)
    formData.append('appId', '29')
    if (searchParams.get('refcode'))
      formData.append('refCode', searchParams.get('refcode'))
    if (searchParams.get('discountCode'))
      formData.append('discountCode', searchParams.get('discountCode'))
    const res = await fetchData(restAPIs.getSubscriptions(formData));
    for (let i = 0; i < res.info.length; i++) {
      if (subId == res.info[i].subscriptionId) {
        setData(res.info[i])
        setDuration(res.info[i].duration)
      }
    }
    setLoading(false)
  };

  useEffect(() => {
    setreferralCode(searchParams.get('refcode'))
    setdiscountCode(searchParams.get('discountCode'))
    fetchSubscriptions();
    fetchTerms();
    getPackageID();
  }, [currentLang, duration]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  const handleOnChange = (key) => (value) => {
    setErrorPhone({
      ...errorPhone,
      [key]: null
    });
    setPhoneData({
      ...data,
      [key]: value
    });
  };

  const handleOnChangePIN = (key) => (value) => {
    setErrorPIN({
      ...errorPIN,
      [key]: null
    });
    setPINData({
      ...data,
      [key]: value
    });
  };

  const handleSubmitPhone = async () => {
    const errors = {
      phone: !phone || !validator.isNumeric(phone) && 'Enter valid phone',
    };
    setErrorPhone(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setLoading(true)
    const res = await fetchData(restAPIs.encryption(phone));
    if (res.status && res.statusCode === 200) {
      setEncryptedPhone(res.result)
      const formD = new FormData();
      formD.append('action', 'orderNow')
      formD.append('subscriptionId', subId)
      formD.append('appId', '29')
      formD.append('paymentMethodId', '2')
      formD.append('credentials', phone)
      const res1 = await fetchData(restAPIs.subscriptionForm(formD));
      if (res1.statusCode == 200) {
        setOrderId(res1.orderId)
        const res2 = await fetchData(restAPIs.encryption(res1.orderId));
        if (res2.status && res2.statusCode === 200) {
          setTxnId(res2.result)
          const formData = new FormData();
          formData.append('action', 'addPhone')
          formData.append('msisdn', res.result)
          formData.append('packageId', packageId)
          formData.append('txnid', res2.result)
          formData.append('language', currentLang?.id)
          const result = await fetchData(restAPIs.simBilling(formData));
          if (result.result.includes("Invalid_MSISDN")) {
            setLoading(false)
            setErrorAlert({ msg: 'Please check your phone number.!', key: Math.random(), open: true, severity: 'success' });
            setTimeout(() => {
              setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
          }
          else if (result.result == "") {
            setLoading(false)
            setErrorAlert({ msg: 'Please check your phone number.!', key: Math.random(), open: true, severity: 'success' });
            setTimeout(() => {
              setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
          }
          else {
            setLoading(true)
            let token_generated = result.result.substring(result.result.indexOf('|') + 1)
            setTokenGenerated(token_generated)
            let inpSnd = inputSend
            setInputSend(!inpSnd)
          }

        }
      }
      else {
        setLoading(false)
        setErrorAlert({ msg: res1.errormessage, key: Math.random(), open: true, severity: 'error' });
        setTimeout(() => {
          setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
        }, 3000);
      }
    }
    else {
      setLoading(false)
      setErrorAlert({ msg: 'Something went wrong, Please try Again.!', key: Math.random(), open: true, severity: 'warning' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }

  }

  const handleSubmitPIN = async () => {
    const errors = {
      pin: !pin,
    };
    setErrorPIN(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setLoadingPIN(true)
    const res = await fetchData(restAPIs.encryption(pin));
    if (res.status && res.statusCode === 200) {
      setEncryptedPIN(res.result)
      const formData = new FormData();
      formData.append('action', 'addPin')
      formData.append('msisdn', encryptedPhone)
      formData.append('packageId', packageId)
      formData.append('txnid', txnId)
      formData.append('token', tokenGenerated)
      formData.append('pin', res.result)
      formData.append('phoneNumber', phone)
      formData.append('detxnId', orderId)
      formData.append('depackageId', (duration == 30) ? '2110' : '2111')
      formData.append('language', currentLang?.id)
      const result = await fetchData(restAPIs.simBilling(formData));
      if (result.statusCode === 200 && result.result.includes("invalidpin")) {
        setErrorAlert({ msg: 'Please enter a valid pin.!', key: Math.random(), open: true, severity: 'success' });
        setTimeout(() => {
          setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
        }, 3000);
        setLoadingPIN(false)
      }
      else if (result.statusCode === 200 && result.result.includes("expiredpin")) {
        setIsResend(true)
        setErrorAlert({ msg: 'Your pin has been expired.!', key: Math.random(), open: true, severity: 'success' });
        setTimeout(() => {
          setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
        }, 3000);
        setLoadingPIN(false)
      }
      else {
        const formD = new FormData();
        formD.append('action', 'orderStatusUpdate')
        formD.append('orderId', orderId)
        formD.append('transactionStatusId', '1')
        formD.append('appId', '29')
        formD.append('txnId', txnId)
        const resultSub = await fetchData(restAPIs.subscriptionForm(formD));
        if (res.statusCode == 200) {
          dispatch({
            type: 'userDetails',
            value: resultSub
          });
          localStorage.setItem('userDetails', JSON.stringify(resultSub));

          setErrorAlert({ msg: 'Subscribed Successfully.!', key: Math.random(), open: true, severity: 'success' });
          setTimeout(() => {
            setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
          }, 3000);
          navigate('/')
        }
      }
    }
    else {
      setLoadingPIN(false)
      setErrorAlert({ msg: 'Something went wrong, Please try Again.!', key: Math.random(), open: true, severity: 'success' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
  }

  const handleResendPIN = async () => {
    setLoadingResendPIN(true)
    const formData = new FormData();
    formData.append('action', 'addPhone')
    formData.append('msisdn', encryptedPhone)
    formData.append('packageId', packageId)
    formData.append('txnid', txnId)
    formData.append('language', currentLang?.id)
    const result = await fetchData(restAPIs.simBilling(formData));
    setLoadingResendPIN(false)
    if (result.result.includes("Invalid_MSISDN")) {
      setErrorAlert({ msg: 'Please check your phone number.!', key: Math.random(), open: true, severity: 'success' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    else if (result.result == "") {
      setErrorAlert({ msg: 'Please check your phone number.!', key: Math.random(), open: true, severity: 'success' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    else {
      let token_generated = result.result.substring(result.result.indexOf('|') + 1)
      setTokenGenerated(token_generated)
    }
  }

  const handleExit = () => {
    referralCode ? navigate('/subscription-payment/' + subId + '?refcode=' + referralCode) : discountCode ? navigate('/subscription-payment/' + subId + '?discountCode=' + discountCode) : navigate('/subscription-payment/' + subId);
  }

  const fetchTerms = async () => {
    const res = await fetchData(restAPIs.etisalatTerms(duration == 30 ? 54 : 55, currentLang?.id));
    setTerms(res.page.description)
    setSubDescrip(res.page.subDescription)
  }

  const getPackageID = async () => {
    const res = await fetchData(restAPIs.encryption(duration == 30 ? '2110' : '2111'));
    setPackageId(res.result)
  }

  const handleCloseTerms = () => {
    setOpenTerms(false)
  }

  const handleTerms = () => {
    setOpenTerms(true)
  }

  return (
    <>
      <Banner
        title={t('etisalat')}
        decription={data?.subscriptionName}
        image={BannerImg}
        withSearch={false}
      />
      <div className='container mt-3'>
        {!isLoading ?
          <div className='row justify-content-center'>
            <div className='col-md-8 text-center'>
              <h3 style={{ fontSize: '20px', marginTop: '10px' }}>{subDescription && subDescription}</h3>
              <p style={{ margin: 0 }}><b>{data?.duration}</b> {t('days')}</p>
              <h5 style={{ margin: 0 }}>{data?.subscriptionName}</h5>
              <h2 style={{ margin: 0 }}>AED {data?.price}.00</h2>
              <p style={{ color: '#037e08' }}>{data?.descline1}</p>
              {!inputSend ? <>
                <div className='mb-4'>
                  <InputField
                    type="number"
                    placeholder={t('enter_ur_mob_rcv_OTP')}
                    label={t('mobile_number')}
                    value={phone}
                    onChange={handleOnChange('phone')}
                    error={errorPhone.phone}
                  />
                </div>

                {errorAlert && errorAlert.open ?
                  <div className='mb-3'>
                    <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
                      {errorAlert.msg}
                    </Alert>

                  </div>
                  : ''}

                <Button
                  label={t('subscribe_through_PIN_Code')}
                  icon={<EastOutlinedIcon />}
                  handleOnClick={handleSubmitPhone}
                  withLoader
                  loading={isLoading}
                  disabled={isLoading}
                  style={{ backgroundColor: '#8bc34a' }}
                />
                <Button
                  label={t('exit')}
                  handleOnClick={handleExit}
                  style={{ backgroundColor: '#000' }}
                  className='mt-2 mb-4'
                />
              </> :
                <>
                  <p style={{ fontSize: '20px', lineHeight: '1' }}>{t('please_enter_activate_subscription')}</p>
                  <div className='mb-4'>
                    <InputField
                      type="number"
                      placeholder={t('pls_enter_the_PIN')}
                      label='PIN Number'
                      value={pin}
                      onChange={handleOnChangePIN('pin')}
                      error={errorPIN.pin}
                    />
                  </div>

                  {errorAlert && errorAlert.open ?
                    <div className='mb-3'>
                      <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
                        {errorAlert.msg}
                      </Alert>

                    </div>
                    : ''}


                  <Button
                    label={t('confirm_your_subscription')}
                    icon={<EastOutlinedIcon />}
                    handleOnClick={handleSubmitPIN}
                    withLoader
                    loading={isLoadingPIN}
                    disabled={isLoadingPIN}
                    style={{ backgroundColor: '#8bc34a' }}
                  />

                  {isResend ? <Button
                    label={t('resend_pin')}
                    icon={<RestartAltIcon />}
                    handleOnClick={handleResendPIN}
                    withLoader
                    loading={isLoadingResendPIN}
                    style={{ backgroundColor: '#777' }}
                    className='mt-2'
                  /> : ''}

                  <Button
                    label={t('exit')}
                    handleOnClick={handleExit}
                    style={{ backgroundColor: '#000' }}
                    className='mt-2 mb-4'
                  />
                </>
              }

              <h3 style={{ fontSize: '20px', marginBottom: '10px' }}>{subDescription && subDescription}</h3>
              <div className='terms_and_conditions'>
                <h1>{t('terms_&_cond')}</h1>
                <p>{t('by_clicking_on_the')}</p>
                <div className='__terms' dangerouslySetInnerHTML={{ __html: terms && terms }}></div>
                <div className='__terms'>
                  <p>• {t('for_complete')} <a onClick={handleTerms} style={{ fontWeight: 'bold', color: 'red', cursor: 'pointer' }}>{t('click_here')}</a></p>
                  <EtisalatTerms open={openTerms} onClose={handleCloseTerms} pageId={duration == 30 ? 54 : 55} />
                </div>
              </div >
            </div>
          </div>
          : <div className='text-center'>
            <CircularProgress color="inherit" />
          </div>
        }
        <Download />
      </div>
    </>
  );
};

export default Etisalat;