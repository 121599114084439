// Core
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import Banner from 'components/commonComponents/Banner';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'
import DownloadBack from 'assets/images/download_back.webp'
import Playstore from 'assets/images/google_play.png'
import Appstore from 'assets/images/app_store.png'
import Web from 'assets/images/web.png'


import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import Button from 'components/commonComponents/Button';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ReactPlayer from 'react-player';

import ImageViewer from 'react-simple-image-viewer';
import { PhotoProvider, PhotoView } from 'react-photo-view';

const AppDetail = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const { appId } = useParams();
    const [imgSet, setImages] = useState([]);

    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const fetchApp = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getEVApps(currentLang?.id, appId));
        setData(res.applications[0]);
        let imgs = []
        res.applications[0].screenshots.forEach(element => {
            imgs.push({ src: element })
        });
        setImages(imgs)
        setLoading(false)
    };

    useEffect(() => {
        fetchApp();
    }, [currentLang]);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };


    return (
        <>
            <Banner
                title='About the App'
                placeholder='Search for destination, places or trips'
                image={BannerImg}
                withSearch={false}
            />

            <div className='container mt-5' style={{ minHeight: '50vh' }}>
                {
                    isLoading ?
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}

                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        :
                        <>
                            <div className='row'>
                                <div className='col-md-3 mb-3'>
                                    <img src={data?.applicationIcon} alt={data?.name} width={'100%'} height={'200px'} style={{ objectFit: 'contain' }} />
                                </div>
                                <div className='col-md-9 mb-3'>
                                    <h4 style={{ color: '#4ac3f5', margin: 0 }}>{data?.tagline}</h4>
                                    <h1 className='__common_page_head' style={{ color: '#439cc8', margin: 0, fontSize: '3rem' }}>{data?.name}</h1>
                                    <div className="pt-1 mt-1 __link_class">
                                        <a href={data && data.appStoreLink} target="_blank"><img src={Appstore} alt="appstore" style={{width: 'auto'}}/></a>
                                        <a href={data && data.googleStoreLink} target="_blank"><img src={Playstore} alt="playstore" style={{width: 'auto'}}/></a>
                                        <a href={data && data.websitelink} target="_blank"><div><img src={Web} alt="web" style={{height: '22px', }} />Website</div></a>
                                    </div>
                                    <p dangerouslySetInnerHTML={{ __html: data?.description }} style={{ lineHeight: '2', fontSize: '17px' }}></p>
                                    {data?.videoURL ? <div className='row justify-content-center'>
                                        <div className='col-md-8'>
                                            <div className='player-wrapper'>
                                                <ReactPlayer url={data?.videoURL} controls={true}
                                                    className='react-player'
                                                    width='100%'
                                                    height='100%'
                                                />
                                            </div>
                                        </div>
                                    </div> : ''}

                                    {data?.screenshots ? <div className='screenShots text-center'>
                                        <PhotoProvider>
                                            {data && data.screenshots?.map((imgItem, index) => {
                                                return (
                                                    <PhotoView src={imgItem} >
                                                        <img src={imgItem} alt={data?.name} />
                                                    </PhotoView>
                                                )
                                            })}
                                        </PhotoProvider>
                                    </div> : ''}
                                </div>
                            </div>
                        </>
                }
            </div>

        </>
    );
};

export default AppDetail;
