// Core
import React, { useState } from 'react';
// Styles
import './styles.scss';

import * as L from 'leaflet';
import { useEffect } from 'react';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useParams } from 'react-router-dom';
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';
import BannerImg from 'assets/images/banner.webp'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BooksByLocationModal from 'components/modals/BooksByLocationModal';


const BookMap = () => {
  const { currentLang } = useSelector((store) => store.commonData);
  const { t } = useTranslation();
  const [location, setLocation] = useState({})
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    fetchMap();
  });

  const fetchMap = async () => {
    const res = await fetchData(restAPIs.awardsByLocation(currentLang?.id));
    const map = L.map("map").setView([15.85778091911655, 52.412109375000004], 4);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '...'
    }).addTo(map);
    map.on('click', function (e) { console.log('map click!', e) });
    for (let i = 0; i < res.locations.length; i++) {
      const element = res.locations[i];
      let marker = L.marker([element.latitude, element.longitude], { opacity: 1, fillOpacity: 0.9, fillColor: '#ffc107' });
      marker.on('click', (e) => {
        L.DomEvent.stopPropagation(e);
        if (e) {
          dispatch({
            type: 'locationByBook',
            value: res.locations[i]
          });
          setLocation(res.locations[i])
          setOpen(true)
        }
      });
      marker.addTo(map);
    }
  }

  const handleClose = () => {
    setOpen(false)
    dispatch({
      type: 'locationByBook',
      value: {}
    });
  }


  return (
    <>
      <Banner
        title={t('map')}
        decription='Check out the books by location'
        image={BannerImg}
        withSearch={false}
      />

      <div className='container mt-5'>
        <h1 className='__common_page_head'>{t('map')}</h1>
        <div className="fs-container mb-5">
          <div className="map-container" id="map" style={{ zIndex: 0 }}></div>
        </div>

        <BooksByLocationModal location={location} open={open} onClose={handleClose} />

        <Download />
      </div>
    </>
  );
};

export default BookMap;
