// Core
import React from 'react';

// Components
import AppRouter from 'routes';

// Style
import 'assets/styles/globalstyles.scss';

const App = () => {
  return <AppRouter />;
};

export default App;
