// Core
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Style
import './styles.scss';

import Slider from "react-slick";

const SliderBanner = (props) => {
    const { title, imageArray } = props;
    const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)

    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        autoplay: true

    };

    return (
        <div className='__slider_banner_sec'>

            <Slider
                asNavFor={nav2}
                ref={slider => (setNav1(slider))}
                {...settings}
            >

                {imageArray.map((item, idx) => {
                    return (
                        <img src={item} alt={idx} key={idx} className='imageLoader'/>
                    )
                })}

            </Slider>
            <div className='__img_thumb_set'>
                <Slider
                    asNavFor={nav1}
                    ref={slider => (setNav2(slider))}
                    slidesToShow={imageArray.length}
                    swipeToSlide={false}
                    focusOnSelect={true}
                    autoplay={true}
                >
                    {imageArray.map((item, idx) => {
                        return (
                            <img
                                src={item}
                                key={idx}
                                alt={idx}
                            />
                        )
                    })}
                </Slider>
            </div>

        </div>
    );
};

export default SliderBanner;

SliderBanner.propTypes = {
    title: PropTypes.string,
    imageArray: PropTypes.any,
};