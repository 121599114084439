// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/commonComponents/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { useDispatch } from 'react-redux';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { useState } from 'react';

const Unsubscribe = (props) => {
  const { onClose, open } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const submitForm = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('appId', '29')
    const res = await fetchData(restAPIs.unSubscribe(formData));
    if (res) {
      dispatch({
        type: 'userDetails',
        value: res
      });
      localStorage.setItem('userDetails', JSON.stringify(res));
      onClose();
    }
    setIsLoading(false);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        maxWidth={'sm'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          {t('unsubscribe')}
        </DialogTitle>
        <DialogContent>
          <div className='mt-2'>
            <p style={{ whiteSpace: 'pre-line' }}>Do you want to unsubscribe</p>
          </div>
        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
            <Button
              withLoader
              loading={isLoading}
              handleOnClick={submitForm}
              label={t('submit')}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Unsubscribe;

Unsubscribe.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
