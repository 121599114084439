// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import Button from 'components/commonComponents/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, DialogContentText, Snackbar } from '@mui/material';

// Styles
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';


const RestoreAccount = (props) => {
  const { onClose, open } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })

  const handleSave = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('action', 'undelete')
    formData.append('appId', '29')
    const res = await fetchData(restAPIs.deleteAccount(formData));
    if (res.statusCode === 200) {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
      let localDetails = JSON.parse(localStorage.getItem('userDetails'));
      localDetails.markDeletion = 0
      localDetails.markDeletion = 0
      dispatch({
        type: 'userDetails',
        value: localDetails
      });
      localStorage.setItem('userDetails', JSON.stringify(localDetails));
      onClose();
    } else {
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    setIsLoading(false);
  };

  const handleClose = (event, reason) => {
    setErrorAlert({})
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        maxWidth={'sm'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          Restore Account
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your account data will be deleted in 30 days, you can suspend deletion within this time period
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>Cancel</a>
            <Button
              withLoader
              loading={isLoading}
              disabled={isLoading}
              handleOnClick={handleSave}
              label='Submit'
            />
          </div>
        </DialogActions>
        {errorAlert && errorAlert.open ? <Snackbar open={errorAlert.open} autoHideDuration={3000} >
          <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
            {errorAlert.msg}
          </Alert>
        </Snackbar> : ''}
      </Dialog>
    </>
  );
};

export default RestoreAccount;

RestoreAccount.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
