import {
  USER_DETAILS,
  TRIPS_LIST,
  ARTICLES_LIST,
  SET_LOCATION,
  SET_LANGUAGE,
  SET_LOCATION_BOOK,
  SEARCH_TRIP,
  SEARCH_ARTICLE,
  ARTICLES_LIST_TRIP,
  CURRENT_TRIP_ID
} from 'config/actionTypes';
import initialState from './initialState';

const commonData = (state = initialState, action) => {
  switch (action.type) {
    case USER_DETAILS:
      return {
        ...state,
        userDetails: action.value
      };
    case TRIPS_LIST:
      return {
        ...state,
        tripsList: action.value
      };
    case ARTICLES_LIST:
      return {
        ...state,
        articlesList: action.value
      };

    case SET_LOCATION:
      return {
        ...state,
        locationByArticle: action.value
      };
    case SET_LANGUAGE:
      return {
        ...state,
        currentLang: action.value
      };
    case SET_LOCATION_BOOK:
      return {
        ...state,
        locationByBook: action.value
      };
    case SEARCH_TRIP:
      return {
        ...state,
        searchTrip: action.value
      };
    case SEARCH_ARTICLE:
      return {
        ...state,
        searchArticle: action.value
      };
    case ARTICLES_LIST_TRIP:
      return {
        ...state,
        articlesList_Trip: action.value
      };
    case CURRENT_TRIP_ID:
      return {
        ...state,
        currentTripId: action.value
      };
    default:
      return state;
  }
};
export default commonData;
