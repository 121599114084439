// Core
import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


// logo
import Logo from 'assets/images/battuta_logo.png'
import Visa from 'assets/images/visa_mastercard.png'

import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import Button from '../Button';
import Unsubscribe from 'components/modals/Unsubscribe';
import { useState } from 'react';


const Footer = () => {
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const openUnsubscribe = () => {
    setShow(true);
  };

  const closeUnsubscribe = (value) => {
    setShow(false);
  };

  return (
    <div className='__footer'>
      <div className='row align-items-end' style={{ width: '100%', margin: 0 }}>
        <div className='col-md-6'>
          <img src={Logo} alt="Logo" className='__logo' />
          <p>{t('powered_by_electronic_village')} <b>© Ibn Battuta</b></p>
          {/* {currentLang?.id === 2 ? <p>Packed with trips and articles, Ibn Battuta <br />Gives you the very insight you need to<br /> Plan your next vacation.</p>
            : <p>مليء بالرحلات والمقالات ، يمنحك ابن بطوطة البصيرة التي تحتاجها للتخطيط لعطلتك القادمة.</p>} */}
        </div>
        <div className='col-md-6'>
          <div className='row'>
            <div className='col-md-4'>
              <h4 >{t('about')}</h4>
              <NavLink to={'about'} end>{t('about_us')}</NavLink>
              <NavLink to={'contact'} end>{t('contact_us')}</NavLink>
            </div>

            <div className='col-md-4'>
              <h4 >{t('trips')}</h4>
              <NavLink to={'trips/featured'} end>{t('featured_trips')}</NavLink>
              <NavLink to={'trips/public'} end>{t('public_trips')}</NavLink>
              <NavLink to={'awards'} end>{t('ib_awards')} </NavLink>
            </div>

            <div className='col-md-4'>
              <h4>{t('social')}</h4>
              <a >{t('facebook')}</a>
              <a >{t('youtube')}</a>
              <a >{t('twitter')}</a>
            </div>
          </div>
        </div>
      </div>
      <div className='row' style={{ width: '100%', margin: 0 }}>
        <div className='col-12'>
          <div className='border-bottom'></div>
          <div className='d-flex justify-content-between mt-2 __onmb'>
            <div className='d-flex __onmb'>
              <NavLink to={'privacy'} end>{t('privacy_policy')}</NavLink>
              <NavLink to={'cookie'} style={{ marginLeft: '15px', marginRight: '15px' }} end>{t('cookie_policy')}</NavLink>
              <NavLink to={'terms'} end>{t('terms_and_conditions')}</NavLink>
            </div>
            <div className="iconSet">
              <div className='_pr'>
                {(userDetails?.fullname && userDetails?.subscription != 0) ?
                  <Button className='mb-1' style={{ width: '100%', padding: '0px 20px', height: '30px', margin: 0, background: '#d3044a' }} handleOnClick={openUnsubscribe} label={t('unsubscribe')} />
                  : <NavLink to={'subscribe'} end>{t('subscribe')}</NavLink>}
              </div>
              <div style={{ display: 'flex' }}>
                <img src='https://ibnbatuta.ae/visa_mastercard.png' alt="" style={{ width: '85px', margin: '0 5px' }} />
                <a href="https://www.facebook.com/alwaraq.net" title="facebook " target="_blank "><FacebookIcon /></a>
                <a href="https://twitter.com/alwaraq" title="twitter " target="_blank "><InstagramIcon /></a>
                <a href="https://www.instagram.com/alwaraq.books/" title="instagram " target="_blank "><TwitterIcon /></a>
              </div>
            </div>
            <Unsubscribe
              open={show}
              onClose={closeUnsubscribe}
            />

          </div>
        </div>
      </div>
    </div>
  );

};

export default Footer;
