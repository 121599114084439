// Core
import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
  font-weight: 600;
  border: none;
  outline: none;
  height: 48px;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  position: relative;
  background: #0780bd;
  border-radius: 40px;
  color: white;
  margin: 0 auto;
  padding: 0px 40px;
  min-width: 160px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;
