// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'
import DownloadBack from 'assets/images/download_back.webp'


import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import validator from 'validator';
import InputField from 'components/commonComponents/InputField';
import Button from 'components/commonComponents/Button';

// icons
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';

import { Alert, Snackbar } from '@mui/material';
import TextArea from 'components/commonComponents/TextArea';

import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA } from 'config/constants';

const Contact = () => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [error, setError] = useState('');
    const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })
    const { name, email='', phone, message, captcha } = data;

    const handleOnChange = (key) => (value) => {
        setError({
            ...error,
            [key]: null
        });
        setData({
            ...data,
            [key]: value
        });
    };


    const handleSubmit = async () => {
        const errors = {
            name: !name,
            email: !email || !validator.isEmail(email) && 'Enter valid email',
            phone: !phone || !validator.isNumeric(phone) && 'Enter valid phone',
            message: !message,
            captcha:!captcha
        };
        setError(errors);
        let isFormValid = Object.values(errors).every((item) => !item);
        if (!isFormValid) return;
        setLoading(true);
        const formData = new FormData();
        formData.append('action', 'contact');
        formData.append('appId', '29');
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('feedback', message);
        const res = await fetchData(restAPIs.contactUs(formData));
        if (res.statusCode === 200) {
            setData({})
            setError({})
            setErrorAlert({ msg: 'Your message has been sent successfully', key: Math.random(), open: true, severity: 'success' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }
        else {
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }
        setLoading(false);

    }

    const onChange = (value) => {
        setError({
            ...error,
            captcha: null
        });
        setData({
            ...data,
            captcha: value
        });
    }


    return (
        <>
            <Banner
                title={t('contact_us')}
                decription={t('contact_for_the_support_and_add_your_feedback')}
                image={BannerImg}
                withSearch={false}
            />
            <div className='container mt-5'>
                <h1 className='__common_page_head'>{t('contact_us')}</h1>
                <div className='row mb-5 __addtrip'>
                    <div className='mb-4'>
                        <InputField
                            type="text"
                            placeholder={t('enter_your_name')}
                            label={t('name')}
                            value={name}
                            onChange={handleOnChange('name')}
                            error={error.name}
                        />
                    </div>
                    <div className='mb-4'>
                        <InputField
                            type="email"
                            placeholder={t('enter_your_email_address')}
                            label={t('email')}
                            value={email}
                            onChange={handleOnChange('email')}
                            error={error.email}
                        />
                    </div>
                    <div className='mb-4'>
                        <InputField
                            type="number"
                            placeholder={t('enter_the_phone_number')}
                            label={t('phone')}
                            value={phone}
                            onChange={handleOnChange('phone')}
                            error={error.phone}
                        />
                    </div>
                    <div className='mb-4'>
                        <TextArea
                            type="text"
                            placeholder={t('write_your_message')}
                            label={t('message')}
                            value={message}
                            onChange={handleOnChange('message')}
                            height={250}
                            error={error.message}
                        />
                    </div>
                    <div className='mb-4'>
                        <p><ReCAPTCHA
                            sitekey={RECAPTCHA}
                            onChange={onChange}
                        /></p>
                        <p style={{color:'red', fontSize:'14px'}}>{error.captcha ? 'Verify captcha' : '' }</p>
                    </div>
                    <div className='mb-5 mt-4 text-center'>
                        <Button
                            label={t('submit')}
                            icon={<EastOutlinedIcon />}
                            handleOnClick={handleSubmit}
                            withLoader
                            loading={isLoading}
                            disabled={isLoading}
                        />
                    </div>
                </div>
                {errorAlert && errorAlert.open ? <Snackbar open={errorAlert.open} autoHideDuration={3000} >
                    <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
                        {errorAlert.msg}
                    </Alert>
                </Snackbar> : ''}
                <Download />
            </div>
        </>
    );
};

export default Contact;
