// Core
import styled, { css } from 'styled-components';

export const InputWrapper = styled.div`
  label {
    font-size: 0.9rem;
    margin-bottom: 10px;
    display: block;
    font-weight: 600;
  }

  textarea {
    display: block;
    width: 100%;
    border: 1px solid #969fad;
    outline: none;
    background-color: #fff;
    font-size: .8rem;
    padding: 25px;
    border-radius: 40px;
    font-weight: 400;
    color: #6e6e6e;
    resize:none;
    ${({ height }) =>
    height &&
    css`
        height: ${ height }px;
    `}
    transition: 0.1s;
    &:focus{
      border: 2px solid ;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  ${({ error }) =>
    error &&
    css`
      label {
        color: #e35061;
        font-weight: 600;
      }
      p {
        color: #e35061 !important;
        font-weight: 400;
        font-size: 14px !important;
        text-align: left;
        margin: 0;
      }
      textarea {
        border-color: #e35061 !important;
      }
    `}

    
`;
