// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


// Styles
import './styles.scss';
import Button from 'components/commonComponents/Button';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import Skelton from 'components/commonComponents/Skelton';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BooksByLocationModal = (props) => {
  const { currentLang } = useSelector((store) => store.commonData);
  const { onClose, open, location } = props;
  const [articles, setBooks] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const handleOnClickBook = (id) => {
    navigate(`/book/${id}`)
    onClose();
  }

  const fetchBooks = async () => {
    setIsLoading(true)
    const res = await fetchData(restAPIs.bookByLocation(currentLang?.id, location.latitude, location.longitude));
    setIsLoading(false)
    if (res?.statusCode === 200) {
      setBooks(res.books);
    }
  };


  useEffect(() => {
    fetchBooks();
  }, [location]);


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        maxWidth={'sm'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          Books
        </DialogTitle>
        <DialogContent>

          <div className='__awards_list'>
            {isLoading ?
              [...Array(2)].map((item, idx) => {
                return (
                  <Skelton key={idx} />
                )
              })
              :
              articles && articles?.map((item, i) => {
                return (
                  <a className="singleBook" key={i} onClick={() => handleOnClickBook(item.bookid)}>
                    <div style={{ position: 'relative', background: '#0001' }}>
                      <img src={item.coverImage} />
                      <div className="bookBind"></div>
                      <span className="subJect">{item.subjectName}</span>
                    </div>
                    <div className="singleBookInner">
                      <h1>{item.name}</h1>
                      <h5>{item.author}</h5>
                    </div>
                  </a>
                )
              })
            }
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>


        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <Button
              handleOnClick={handleClose}
              label={t('cancel')}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BooksByLocationModal;

BooksByLocationModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  location: PropTypes.any
};
