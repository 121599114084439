// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// Styles
import './styles.scss';
import Button from 'components/commonComponents/Button';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useSelector } from 'react-redux';

const Zodiac = (props) => {
  const { onClose, open, date } = props;
  const [zodiac, setDataZodiac] = useState([])
  const [mansion, setDataMansion] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { currentLang } = useSelector((store) => store.commonData);
  const { t } = useTranslation();
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    fetchZodiac();
  }, [date]);

  const fetchZodiac = async () => {
    setIsLoading(true)
    const formData = new FormData();
    formData.append('astroDate', date)
    formData.append('language', currentLang?.id)
    const res = await fetchData(restAPIs.getAstroInDetail(formData));
    setDataZodiac(res.astro[0].zodiac);
    setDataMansion(res.astro[0].mansion);
    setIsLoading(false);
  }

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class_tabs'
        maxWidth={'md'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          {t('zodiac')}
        </DialogTitle>
        <DialogContent>
          <div >
            <Tabs value={value} onChange={handleChange} variant="fullWidth" centered>
              <Tab label={t('zodiac')} {...a11yProps(0)} />
              <Tab label={t('mansion')} {...a11yProps(1)} />
            </Tabs>

            <div className='__tab_content'>
              <div
                role="tabpanel"
                hidden={value !== 0}
                id={`simple-tabpanel-0`}
                aria-labelledby={`simple-tab-0`}
              >
                <div className='row __heigh'>
                  <div className='col-md-3'>
                    <img src={zodiac?.zodiacPic} alt={zodiac?.zodiacName} />
                  </div>
                  <div className='col-md-9'>
                    <h1>{zodiac?.zodiacName}</h1>
                    <p dangerouslySetInnerHTML={{ __html: zodiac?.description }}></p>
                  </div>
                </div>
              </div>
              <div
                role="tabpanel"
                hidden={value !== 1}
                id={`simple-tabpanel-1`}
                aria-labelledby={`simple-tab-1`}
              >
                <div className='row __heigh'>
                  <div className='col-md-3'>
                    <img src={mansion?.mansionPic} alt={mansion?.mansionName} />
                  </div>
                  <div className='col-md-9'>
                    <h1>{mansion?.mansionName}</h1>
                    <p dangerouslySetInnerHTML={{ __html: mansion?.description }}></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <Button
              handleOnClick={handleClose}
              label={t('cancel')}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Zodiac;

Zodiac.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  date: PropTypes.any
};
