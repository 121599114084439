// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// Styles
import './styles.scss';
import Button from 'components/commonComponents/Button';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';

const EtisalatTerms = (props) => {
  const { onClose, open, pageId } = props;
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    fetchTerms();
  }, []);

  const fetchTerms = async () => {
    setIsLoading(true)
    const res = await fetchData(restAPIs.etisalatTerms(pageId));
    setData(res.page);
    setIsLoading(false);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class_tabs'
        maxWidth={'md'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
          {data?.name}
        </DialogTitle>
        <DialogContent>
          
          <div className='mt-3'>
            <p className='__innerCont' dangerouslySetInnerHTML={{ __html: data?.description }}></p>
          </div>

        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <Button
              handleOnClick={handleClose}
              label={t('cancel')}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EtisalatTerms;

EtisalatTerms.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  pageId:PropTypes.any
};
