// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { StyledTrip } from './style';
import { useTranslation } from 'react-i18next';


const Trip = (props) => {
  const { t } = useTranslation();
  const { title, para, image, handleOnClick, premium, author, ...rest } = props;
  return (
    <StyledTrip {...rest} onClick={handleOnClick} >
      <img src={image} alt={title} />
      <h1>{title}</h1>
      <h4>{author}</h4>
      <p>{para && para.substring(0, 80)} <a>...{t('learn_more')}</a></p>
      {premium && <div className='__premium'>{t('premium')}</div>}
    </StyledTrip>
  );
};

export default Trip;

Trip.propTypes = {
  title: PropTypes.string,
  para: PropTypes.string,
  image: PropTypes.string,
  author: PropTypes.string,
  handleOnClick: PropTypes.func,
  premium:PropTypes.bool
};
