// Core
import styled, { css } from 'styled-components';

export const StyledArticle = styled.div`
border-radius: 25px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 0px 10px #00000029;
border: 1px solid #E3E3E3;
padding: 10px;
cursor:pointer;
div{
  padding: 0 6px;
}
img {
  width: 100%;
  height: 150px;
  object-fit:cover;
  border-radius: 27px;
  background: #eee;
  min-height: 150px;
  min-width: 100%;
  text-align: center;
}

h1 {
  font-size: 17px !important;
  margin: 10px 0 !important;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1 0%;
  min-height: 40px;
}
p{
  font-size: 13px !important;
  color:#b1b1b1 !important;
  margin: 0 !important;
}
`;
