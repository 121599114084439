// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { Button, CircularProgress } from '@mui/material';
import Highlight from 'components/commonComponents/Highlight';

const Search = () => {
    const { currentLang, searchTrip } = useSelector((store) => store.commonData);
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [isLastPage, setIsLastPage] = useState(true);
    const [searchtext, setSearchText] = useState(searchTrip?.searchPhrase)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [isLoadingPage, setLoadingPage] = useState(false);

    const handleSubmit = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('language', currentLang?.lang);
        formData.append('searchPhrase', searchtext);
        formData.append('page', page)
        const res = await fetchData(restAPIs.searchAll(formData));
        if (res.statusCode === 200) {
            dispatch({
                type: 'searchTrip',
                value: {
                    searchPhrase: searchtext,
                    searchAll: res.data
                }
            });
            setIsLastPage(res.isLastPage)
        }
        setLoading(false);
    }

    const handlePageClick = () => {
        let curren = page
        setPage(curren + 1);
        fetchPaginate(curren + 1)
    }

    const fetchPaginate = async (page) => {
        setLoadingPage(true);
        const formData = new FormData();
        formData.append('language', currentLang?.lang);
        formData.append('searchPhrase', searchtext);
        formData.append('page', page)
        const res = await fetchData(restAPIs.searchAll(formData));
        if (res.statusCode === 200) {
            dispatch({
                type: 'searchTrip',
                value: {
                    searchPhrase: searchtext,
                    searchAll: searchTrip?.searchAll.concat(res.data)
                }
            });
            setIsLastPage(res.isLastPage)
        }
        setLoadingPage(false);
    }

    const handleOnChangeSearch = (value) => {
        setSearchText(value)
    }

    const handleSearchItem = (id, contentLanguage) => {
        dispatch({
            type: 'searchArticle',
            value: {
                searchPhrase: searchtext,
                articles: [],
                contentLanguage : contentLanguage
            }
        });
        navigate('/search/articles/'+id)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <>
            <Banner
                placeholder={'Search Trips here'}
                image={BannerImg}
                withSearch={true}
                handleOnClick={handleSubmit}
                value={searchtext}
                handleOnChange={handleOnChangeSearch}
                onKeyDown={handleKeyDown}
            />
            <div className='container mt-5'>
                {
                    isLoading ? <div className='text-center'>
                        <CircularProgress color="inherit" />
                    </div> :
                        searchTrip?.searchAll?.map((item, idx) => {
                            return (
                                <div className='row mb-3' key={idx}>
                                    <div className='__search_item' onClick={() => handleSearchItem(item?.tripId, item?.contentLanguage)}>
                                        <img src={item?.coverImage} alt={item?.tripName} />
                                        <p>
                                            <Highlight text={item?.tripName} highlight={searchtext} />
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                }
                {isLoadingPage ? <div className='text-center'>
                        <CircularProgress color="inherit" />
                    </div> :''}

                {isLoading ? '' :
                    <div className='__pagination' style={{ justifyContent: 'center' }}>
                        {isLastPage ? '' : <Button onClick={handlePageClick} className='_hand_btn'>{t('more')} </Button>}
                    </div>
                }

                <Download />
            </div>
        </>
    );
};

export default Search;
