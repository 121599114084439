// Core
import React, { useState } from 'react';

// Styles
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useEffect } from 'react';
// icons

const Sitemap = () => {
  const [resultr, setResult] = useState('')

  const ids = [
    748, 764, 487, 928, 900, 931, 450, 743, 929, 447, 636, 494, 439, 642, 713, 721, 728, 729, 730, 752, 756, 750, 441, 741, 755, 473, 795, 634, 482, 712, 623, 633, 635, 717, 725, 449, 478, 625, 782, 446, 645, 475, 474, 639, 624, 785, 773, 481, 638, 637, 486, 477, 627, 463, 468, 493, 646, 641, 435, 471, 469, 492, 485, 647, 650, 673, 674, 670, 671, 676, 675, 677, 678, 679, 680, 681, 682, 683, 707, 684, 685, 686, 687, 688, 690, 689, 691, 692, 693, 694, 708, 695, 696, 697, 698, 699, 700, 701, 702, 703, 704, 705, 706, 731, 746, 778, 779, 780, 803, 781, 784, 787, 793, 790, 792, 794, 796, 798, 797, 799, 800, 801, 802, 805, 806, 807, 808, 809, 810, 811, 812, 814, 816, 645, 453, 726, 761, 742, 757, 762, 436, 913, 626, 870, 871
  ]

  useEffect(() => {
    fetchAwards()
  }, []);


  const fetchTrips = async () => {
    let result = ''
    for (let k = 1; k < 25; k++) {
      const res = await fetchData(restAPIs.getRecentPublicTrips(k, 1));
      for (let index = 0; index < res.recent.length; index++) {
        let abc = "<url><loc>https://ibnbatuta.ae/trip/" + res.recent[index]?.tripId + "</loc><changefreq>weekly</changefreq></url>"
        result = result + abc
        
        if (res.recent[index]?.articleCount > 0) {
          for (let j = 1; j <= Math.round((res.recent[index]?.articleCount)/30); j++) {
            const formData2 = new FormData();
            formData2.append('tripId', res.recent[index]?.tripId);
            formData2.append('page', j);
            formData2.append('contentLanguage', 1)
            const res2 = await fetchData(restAPIs.getArticles(formData2));
            console.log("res2.articles", j);
            if(res2.articles.length > 0)
            {
              for (let k = 0; k < res2.articles.length; k++) {
                let def = "<url><loc>https://ibnbatuta.ae/article/" + res.recent[index]?.tripId + "/" + res2.articles[k]?.articleId + "</loc><changefreq>weekly</changefreq></url>"
                result = result + def
              }
            }
          }
        }
      }

    }

    setResult(result)
  }


  const fetchAwards = async () => {
    let result = ''
    for (let k = 0; k < 10; k++) {
      const res = await fetchData(restAPIs.awardsList(1, null, null, k));
      for (let index = 0; index < res.items.length; index++) {
        let def = "<url><loc>https://ibnbatuta.ae/book/" + res.items[index]?.awardId + "</loc><changefreq>weekly</changefreq></url>"
        result = result + def
      }

    }
    setResult(result)

    
}




  return (
    <>
      <div className='container __innerTopPad' style={{ paddingTop: '20vh' }}>
        {resultr}
      </div>

    </>
  );
};

export default Sitemap;