// Core
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import Button from '../Button';

import LocalPoliceIcon from '@mui/icons-material/LocalPolice';

const PremiumWidget = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userDetails } = useSelector((store) => store.commonData);

  const handleClick = () => {
    navigate('/subscribe')
  }



  return (
    <>
      {userDetails && userDetails.subscription != 0 ? <Button className='mb-1' label={t('premium_member')} icon={<LocalPoliceIcon/>}/> :

        <Button className='mb-1' style={{width:'100%'}} label={t('get_premium')} handleOnClick={handleClick} />
        // <div className='__premium_sec'>
        //   <h1>Unlock 300+ more Trips and Articles</h1>
        //   <p>Go Premium for $199/month and unlock the full features of Ibn Battuta travel blog. Receive Travel tips and tricks via email.</p>
        //   <button onClick={handleClick}>{t('get_premium')}</button>
        // </div>
      }
    </>
  );
};

export default PremiumWidget;
