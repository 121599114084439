// Core
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//Assets
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import AddIcon from '@mui/icons-material/Add';
import { amber } from '@mui/material/colors';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import HomeIcon from '@mui/icons-material/Home';
import PublicIcon from '@mui/icons-material/Public';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import RoofingIcon from '@mui/icons-material/Roofing';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SettingsIcon from '@mui/icons-material/Settings';
import RedeemIcon from '@mui/icons-material/Redeem';
import SearchIcon from '@mui/icons-material/Search';

// logo
import Logo from 'assets/images/battuta_logo.png'

// Styles
import './styles.scss';
import { Avatar, Box, Drawer, Fab } from '@mui/material';
import Login from 'components/modals/Login';

import 'react-photo-view/dist/react-photo-view.css';
import { LoginOutlined } from '@mui/icons-material';
import Button from '../Button';


const Header = () => {
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const { i18n } = useTranslation();
  const [userInfo, setUserInfo] = useState(userDetails);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [drawerState, setDrawerState] = useState(false)
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const localDetails = localStorage.getItem('userDetails');
    i18n.changeLanguage(currentLang.lang);

    if (currentLang.lang === 'ar') {
      document.body.classList.add('arabic');
    }
    else {
      document.body.classList.remove('arabic')
    }

    if (localDetails) {
      setUserInfo(JSON.parse(localDetails));
      dispatch({
        type: 'userDetails',
        value: JSON.parse(localDetails)
      });
    }
  }, []);

  const handleLogout = () => {
    dispatch({
      type: 'userDetails',
      value: null
    });
    localStorage.setItem('userDetails', '');
    localStorage.setItem('token', '');
    navigate('/');
  };

  const gotoHome = () => {
    navigate('/')
  }

  const serachClick = () => {
    navigate('/search')
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value) => {
    setAnchorEl(null);
    if (value) {
      dispatch({
        type: 'currentLang',
        value: { lang: value, id: value === 'ar' ? 1 : 2 }
      });
      i18n.changeLanguage(value);
      if (value === 'ar') {
        document.body.classList.add('arabic');
      }
      else {
        document.body.classList.remove('arabic')
      }
    }
  };

  const handleClickOpen = () => {
    setShow(true);
  };

  const handleCloseModel = (value) => {
    setShow(false);
  };

  const toggleDrawer = (value) => {
    setDrawerState(value)
  }

  const handleAddTrip = () => {
    userDetails ? navigate('add-trip') : setShow(true);
  }

  return (
    <div className='__header'>
      <div className='d-flex align-items-center'>
        <div className='__dr_menu'>
          <button onClick={() => toggleDrawer(true)} style={{ color: '#fff' }}><MenuRoundedIcon /></button>
          <Drawer
            anchor={'left'}
            open={drawerState}
            onClose={() => toggleDrawer(false)}
          >
            <Box
              sx={{ width: 300 }}
              role="presentation"
              onClick={() => toggleDrawer(false)}
              onKeyDown={() => toggleDrawer(false)}
            >
              <div className='__drawer_menu'>
                <div className='__logo_div'>
                  <img src={Logo} alt="logo" className='__logo' onClick={gotoHome} />
                </div>
                <NavLink to={'/'} end><HomeIcon /> {t('home')}</NavLink>
                <NavLink to={'trips/featured'} end><FolderSpecialIcon /> {t('featured_trips')}</NavLink>
                <NavLink to={'trips/public'} end><PublicIcon />{t('public_trips')}</NavLink>
                <NavLink to={'awards'} end><MilitaryTechIcon />{t('ib_awards')}</NavLink>
                {(userDetails?.fullname && userDetails?.subscription != 0) ?
                  <Button className='mb-1' style={{ width: '100%', padding: '0 10px' }} label='Premium Member' icon={<LocalPoliceIcon />} />
                  : <NavLink to={'subscribe'} end><LocalPoliceIcon />{t('subscribe')}</NavLink>}

                <div className='border-bottom pt-2 mb-3'></div>
                {/* <NavLink to={'about'} end><RedeemIcon />Refer & Earn</NavLink> */}
                <NavLink to={'about'} end><RoofingIcon />{t('about_us')}</NavLink>
                <NavLink to={'help-and-support'} end><HelpCenterIcon />{t('help_support')}</NavLink>
                <NavLink to={'contact'} end><SupportAgentIcon />{t('contact_us')}</NavLink>

                <div className='border-bottom pt-2 mb-3'></div>

                {
                  userDetails?.fullname ?
                    <>
                      <NavLink to={'settings'} end><SettingsIcon />{t('settings')}</NavLink>
                      <a onClick={handleLogout}><LogoutIcon />{t('sign_out')}</a>
                    </>
                    :
                    <a onClick={handleClickOpen}><LoginOutlined /> {t('log_in')}</a>}
              </div>
            </Box>
          </Drawer>
        </div>
        <img src={Logo} alt="logo" className='__logo' onClick={gotoHome} />
      </div>

      <div className='__menu'>
        <NavLink to={'trips/featured'} end>{t('featured_trips')}</NavLink>
        <NavLink to={'trips/public'} end>{t('public_trips')}</NavLink>
        <NavLink to={'awards'} end>{t('ib_awards')}</NavLink>
        <NavLink to={'about'} end>{t('about_us')}</NavLink>
      </div>

      <div className='d-flex align-items-center'>
        <div className='__profile_sec'>
          <NavLink to={'search'} end style={{ color: '#fff', textDecoration: 'none' }}><SearchIcon sx={{ fontSize: 30 }} /></NavLink>
          <button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className='lang_btn'
          >
            {currentLang?.lang === 'ar' ? 'عربي' : 'ENG'}
          </button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClick={() => handleClose('')}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => handleClose('ar')} style={{ minWidth: '100px' }}>عربي</MenuItem>
            <MenuItem onClick={() => handleClose('en')} style={{ minWidth: '100px' }}>ENG</MenuItem>
          </Menu>
          {userDetails?.fullname ? <NavLink to={'settings'} end style={{ color: '#fff', textDecoration: 'none' }}><AccountCircleIcon sx={{ fontSize: 30 }} /></NavLink>
            :
            <a style={{ margin: '0 10px', color: '#fff', fontSize: '14px', cursor: 'pointer' }} onClick={handleClickOpen}>{t('log_in')}</a>}
        </div>
        <div className='__add_trip' onClick={handleAddTrip}>
          <AddIcon />
          <a>{t('add_new')}</a>
        </div>
      </div>

      <Login
        open={show}
        onClose={handleCloseModel}
      />
      <div className='__add_trip_small' onClick={handleAddTrip}>
        <Fab color="primary" aria-label="add">
          {/* <NavLink to={'add-trip'} end style={{ color: '#fff', textDecoration: 'none' }}><AddIcon /></NavLink> */}
          <a><AddIcon /></a>
        </Fab>
      </div>

    </div>
  );
};

export default Header;
