// Core
import React, { useState, useEffect, useRef } from 'react';
// Styles
import './styles.scss';

import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TextArea from 'components/commonComponents/TextArea';
import Select from 'components/commonComponents/Select';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import Button from 'components/commonComponents/Button';
import { FileOpenOutlined } from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FileUpload = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingTrips, setLoadingTrips] = useState(false);
  const [tripId, setTripId] = useState('');
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const [trips, setTrips] = useState([]);
  const hiddenFile = useRef(null);
  const [open, setOpen] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const navigate = useNavigate();

  const fetchTrips = async (page = 1) => {
    try {
      setLoadingTrips(true);
      const res = await fetchData(restAPIs.getFeaturedTrips(page, currentLang?.id));
      if (res?.statusCode === 200) {
        setTrips((prevTrips) => [...prevTrips, ...res.featured]);
        setIsLastPage(res.isLastPage);
      }
    } catch (error) {
      console.error('Error fetching trips:', error);
    } finally {
      setLoadingTrips(false);
    }
  };

  useEffect(() => {
    setTrips([]); // Reset trips when language changes
    setCurrentPage(1);
    fetchTrips(1);
  }, [currentLang]);

  const handleLoadMoreTrips = () => {
    if (!isLastPage) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchTrips(nextPage);
    }
  };

  const fetchArticles = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('tripId', tripId);
      formData.append('page', 0);
      formData.append('contentLanguage', currentLang?.id);
      const res = await fetchData(restAPIs.getArticles(formData));
      if (res?.statusCode === 200) {
        const articlesData = res.articles.map(article => ({
          text: article.articleName + article.descriptionStriped
        }));

        let temp = '';
        articlesData.forEach(article => {
          temp += article.text;
        });
        return temp;
      }
    } catch (error) {
      console.error('Error fetching articles:', error);
    } finally {
    }
  };

  const handleOnChange = (field) => (e) => {
    if (field === 'message') {
      setMessage(e);
    }
    if (field === 'trips') {
      const value = e.target.value;
      setTripId(value);
    }
  };

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formData = new FormData();
    const articles = await fetchArticles();
    formData.append('text', message + articles);
    formData.append('trip_id', tripId);
    files.forEach((file) => {
      formData.append('files', file);
    });
    const res = await fetchData(restAPIs.creteModel(formData));
    if (res.status_code === 200) {
      setOpen(true);
    }
    else {
      setOpenFail(true);
    }
    setLoading(false);

  }

  const handleCoverClick = event => {
    hiddenFile.current.click();
  };

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <div className="container mt-5">
      <form>
        <div className="mb-3">
          <Select
            selectedvalue={tripId}
            handleChange={handleOnChange('trips')}
            label={t('Trips')}
            name="trips"
            placeholder={t('Trips')}
            options={trips.map((trip) => ({ value: trip.tripId, text: trip.tripName }))}
          />
          {!isLastPage && (
            <p style={{ textAlign: 'right',  display:'flex', justifyContent:'end', alignItems:'center', marginTop: '10px', marginRight: '20px' }}><a style={{ cursor: 'pointer', display:'flex', justifyContent:'end', alignItems:'center', width:'max-content', border: '1px solid #ccc', padding: '4px 10px', background: '#eee' }} onClick={() => handleLoadMoreTrips()}>{loadingTrips ? <CircularProgress size={20} /> :'Load More Trips'}</a></p>
          )}
        </div>
        <div className='__cover_image mb-3' onClick={handleCoverClick}>
          <div>
            <FileOpenOutlined />
            <span>Upload your files</span>
          </div>
          <FileUploadOutlinedIcon />
          <input type="file" multiple onChange={handleFileChange} ref={hiddenFile} style={{ display: 'none' }} />
        </div>
        <div className="mb-3">
          {files.length > 0 && (
            <div className="file-list">
              {files.map((file, index) => (
                <div key={index} className="file-item">
                  <span className="file-name">{file.name}</span>
                  <button
                    type="button"
                    className="btn btn-link text-danger"
                    onClick={() => handleRemoveFile(index)}
                  >
                    {t('Remove')}
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="mb-3">
          <TextArea
            type="text"
            placeholder={'More details'}
            label={'More details'}
            value={message}
            onChange={handleOnChange('message')}
            height={250}
          />
        </div>
        <Button
          label={t('submit')}
          icon={<EastOutlinedIcon />}
          handleOnClick={handleSubmit}
          withLoader
          loading={loading}
          disabled={loading}
          style={{ margin: 0, marginBottom: '20px' }}
        />
      </form>
      <>
        <Dialog open={open} onClose={handleClose} disableBackdropClick>
          <DialogTitle>Success</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('Your files have been successfully uploaded.')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button handleOnClick={handleClose} label='Close' />
          </DialogActions>
        </Dialog>
      </>

      <>
        <Dialog open={openFail} onClose={() => setOpenFail(false)} disableBackdropClick>
          <DialogTitle>Failed</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Error while uploading files
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button handleOnClick={() => setOpenFail(false)} label='Close' />
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
};

export default FileUpload;