// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// components
import Article from 'components/commonComponents/Article';
import Button from 'components/commonComponents/Button';
import TextArea from 'components/commonComponents/TextArea';
import Comment from 'components/commonComponents/Comment';
import SliderBanner from 'components/commonComponents/SliderBanner';

import CommentIcon from '@mui/icons-material/Comment';
import LinkIcon from '@mui/icons-material/Link';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import StarsOutlinedIcon from '@mui/icons-material/StarsOutlined';
import EditIcon from '@mui/icons-material/Edit';
import TranslateIcon from '@mui/icons-material/Translate';
import SendIcon from '@mui/icons-material/Send';
import ForumIcon from '@mui/icons-material/Forum';

// Styles
import './styles.scss';

// Assets
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';

import { useSelector } from 'react-redux';
import Login from 'components/modals/Login';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Menu, IconButton, Alert, Snackbar, Fab, Tabs, Tab } from '@mui/material';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import EditComment from 'components/modals/EditComment';

import SocialShare from 'components/modals/SocialShare';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { useSpeechSynthesis } from 'react-speech-kit';
import Zodiac from 'components/modals/Zodiac';
import PremiumWidget from 'components/commonComponents/PremiumWidget';
import Skelton from 'components/commonComponents/Skelton';
import ReactPlayer from 'react-player';
import Highlight from 'components/commonComponents/Highlight';
// import { MusicNoteOutlined, SpatialAudioOffIcon } from '@mui/icons-material';
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import AudioPlayerModal from 'components/modals/AudioPlayer';
import InputField from 'components/commonComponents/InputField';
import FAQ from 'components/modals/FAQ';
import QA from 'assets/images/question-and-answer.png'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { FileCopy } from '@mui/icons-material';

const ViewArticle = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const { t } = useTranslation();

    const { tripId, articleId } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [imageArray, setImageArray] = useState([]);

    const [errorComment, setErrorComment] = useState('')
    const [commentData, setCommentData] = useState({})
    const [isLoadingComment, setIsLoadingComments] = useState(false);
    const [comments, setComments] = useState([]);
    const [showLogin, setShowLogin] = useState(false);

    const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })
    const [anchorElMenu, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(null);
    const [openDeleteComment, setOpenDeleteComment] = useState(false);
    const { comment = '' } = commentData;
    const [openEditComment, setOpenEditComment] = useState('');
    const [mapUrl, setMapUrl] = useState('')
    const [assets, setAssets] = useState([])
    const [urls, setUrls] = useState([]);
    const [openShareSocial, setOpenShareSocial] = useState(false);
    const [summary, setSummary] = useState('')
    const [description, setDiscription] = useState('')

    const commentsRef = useRef(null);
    const assetsRef = useRef(null)
    const shareRef = useRef(null)
    const linksRef = useRef(null);

    const [openZodiac, setOpenZodiac] = useState(false)
    const { speak, cancel, speaking } = useSpeechSynthesis();
    const [valueToSpeak, setValueToSpeak] = useState('');

    const [anchorElMenuLang, setAnchorElLang] = useState(null);
    const [openMenuLang, setOpenMenuLang] = useState(false);
    const [tempDescr, setTempDesc] = useState('')

    const [articles, setArticles] = useState([])
    const [isLoadingArticlre, setIsLoadingArticlre] = useState(false)
    const [articlesRel, setArticlesRel] = useState([])

    const [videos, setVideos] = useState([])
    const [highlight, setHighlight] = useState('')

    const [showAudioPlayer, setShowAudioPlayer] = useState(false);
    const [audioFiles, setAudioFiles] = useState([])

    const [isLoadingCht, setIsLoadingCht] = useState(false)
    const [chatgptResponse, setChatgptResponse] = useState('')

    const [datacht, setDataCht] = useState({});
    const [error, setError] = useState('');

    const [showFAQ, setShowFAQ] = useState(false)

    const { searchPhrase } = datacht


    const handleClickMenu = (event, value) => {
        setAnchorEl(event.currentTarget);
        setOpenMenu(value)
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
        setOpenMenu(null)
    };


    const handleOpenLanguage = (event) => {
        setAnchorElLang(event.currentTarget);
        setOpenMenuLang(true)
    };
    const handleCloseMenuLang = () => {
        setAnchorElLang(null);
        setOpenMenuLang(false)
    };

    const handleLangClick = (value) => {
        if (value === 'default') {
            fetchArticle()
        }
        else {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            if (tempDescr.length > 3900) {
                let newStr = tempDescr.match(/(.|[\r\n]){1,3000}/g);
                let tempAr = []
                newStr.forEach(element => {
                    fetch(`https://translate.googleapis.com/translate_a/single?client=gtx&sl=${currentLang?.lang}&tl=${value}&dt=t&q=${element}`, requestOptions)
                        .then(response => response.json())
                        .then(res => {
                            let arr = []
                            for (let i = 0; i < res[0].length; i++) {
                                arr.push(res[0][i][0])
                            }
                            tempAr.push(arr.join());
                            setDiscription(tempAr.join())
                        }

                        ).catch(error => console.log('error', error));

                })
            }
            else {
                fetch(`https://translate.googleapis.com/translate_a/single?client=gtx&sl=${currentLang?.lang}&tl=${value}&dt=t&q=${tempDescr}`, requestOptions)
                    .then(response => response.json())
                    .then(res => {
                        let arr = []
                        for (let i = 0; i < res[0].length; i++) {
                            arr.push(res[0][i][0])
                        }
                        setDiscription(arr.join());
                    }
                    ).catch(error => console.log('error', error));
            }
        }
    }

    const fetchArticle = async () => {
        const res = await fetchData(restAPIs.getArticleDetail(tripId, articleId, currentLang?.id));
        if (res?.statusCode === 200) {
            setImageArray([res.article[0].coverImage, ...res.article[0].assets])
            setData(res.article[0]);
            setValueToSpeak(res.article[0].articleName.concat(res.article[0].descriptionStriped))
            let url = "https://maps.google.com/maps?q=" + res.article[0]?.location?.latitude + "," + res.article[0]?.location?.latitude + "&output=embed&zoom=10"
            setMapUrl(url)
            setUrls(res.article[0].urls)
            setAssets(res.article[0].assets)
            if (getParameterByName('searchtext', window.location.href))
                setDiscription(generateHighlightedHTML(res.article[0].description, highlight))
            else
                setDiscription(res.article[0].description)
            setTempDesc(res.article[0].descriptionStriped)
            let sum = res.article[0].articleName + '\n\n' + res.article[0].description + '\n\n'
            res.article[0].urls.forEach(element => {
                sum += element + '\n'
            });
            sum += 'From Ibn Battuta Project.\nBy Electronic Village \n\nAppstore : http://ibios.electronicvillage.org/ \nPlaystore : http://ibandroid.electronicvillage.org/\nWeb:'
            setSummary(sum)

            let vidos = []
            if (res.article[0].urls.length > 0) {
                for (let i = 0; i < res.article[0].urls.length; i++) {
                    const element = res.article[0].urls[i];
                    if (element.includes('vimeo') || element.includes('youtube'))
                        vidos.push(element)
                }
                setVideos(vidos)
            }
            if (res.article[0].audios)
                setAudioFiles(res.article[0].audios)
        }
    };

    const fetchComments = async () => {
        const res = await fetchData(restAPIs.getArticleComments(tripId, articleId));
        if (res?.statusCode === 200) {
            setComments(res.comments);
        }
    };

    const getParameterByName = (name, url) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchArticle();
        fetchComments()
        fetchArticles();
        if (getParameterByName('searchtext', window.location.href))
            setHighlight(getParameterByName('searchtext', window.location.href))
    }, [articleId, currentLang]);


    const handleOnClickArticle = (id) => {
        navigate('/article/' + tripId + '/' + id);
    }

    const handleEditArticle = () => {
        navigate('/edit-article/' + tripId + '/' + articleId);
    }

    const handleGotoTrip = () => {
        if (getParameterByName('contentLanguage', window.location.href))
            navigate('/trip/' + tripId + '?contentLanguage=' + getParameterByName('contentLanguage', window.location.href));
        else
            navigate('/trip/' + tripId);
    }

    const handleCloseLoginModel = () => {
        setShowLogin(false);
    };
    const handleOpenLoginModel = () => {
        setShowLogin(true);
    }

    const handleDeleteCommentClick = () => {
        setOpenDeleteComment(true);
    };

    const handleCloseDeleteComment = async (action, commentId) => {
        setOpenDeleteComment(false);
        if (action) {
            setAnchorEl(null);
            setOpenMenu(null)
            const formData = new FormData();
            formData.append('action', 'delete');
            formData.append('tripId', tripId);
            formData.append('commentId', commentId);
            formData.append('articleId', articleId);
            const res = await fetchData(restAPIs.addCommentTrip(formData));
            if (res.statusCode === 200) {
                fetchComments();
                setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            }
            else {
                setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            }
        }
    };

    const handleOnChangeComment = (key) => (value) => {
        setErrorComment({
            ...errorComment,
            [key]: null
        });
        setCommentData({
            ...commentData,
            [key]: value
        });
    };

    const handleSubmitComment = async () => {
        const errors = {
            comment: !comment,
        };
        setErrorComment(errors);
        let isFormValid = Object.values(errors).every((item) => !item);
        if (!isFormValid) return;
        setIsLoadingComments(true);
        const formData = new FormData();
        formData.append('action', 'add');
        formData.append('tripId', tripId);
        formData.append('articleId', articleId);
        formData.append('comment', comment);
        const res = await fetchData(restAPIs.addCommentArticle(formData));
        if (res.statusCode === 200) {
            fetchComments();
            setCommentData({})
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }
        else {
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }
        setIsLoadingComments(false);
    };

    const handleEditCommentClick = (value) => {
        setOpenEditComment(value);
    };

    const handleCloseEditComment = (value) => {
        setOpenEditComment('');
        handleCloseMenu();
        if (value)
            fetchComments();
    }

    const scrollToBottom = (checkRef) => {
        if (checkRef == 'commentsRef')
            commentsRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (checkRef == 'assetsRef')
            assetsRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (checkRef == 'shareRef')
            shareRef.current?.scrollIntoView({ behavior: 'smooth' });
        if (checkRef == 'linksRef')
            linksRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleOpenShareSocial = () => {
        setOpenShareSocial(true);
    };
    const handleCloseShareSocial = () => {
        setOpenShareSocial(false);
    };

    const handleSpeak = () => {
        speak({ text: valueToSpeak && valueToSpeak, rate: 1.2 })
    }

    const handleOpenZodiac = () => {
        setOpenZodiac(true);
    };
    const handleCloseZodiac = () => {
        setOpenZodiac(false);
    };

    const fetchArticles = async () => {
        setIsLoadingArticlre(true)
        const formData = new FormData();
        formData.append('tripId', tripId);
        formData.append('contentLanguage', currentLang?.id)
        const res = await fetchData(restAPIs.getArticles(formData));
        setIsLoadingArticlre(false)
        if (res?.statusCode === 200) {
            let arts = []
            let arts2 = []
            for (let i = 0; i < res.articles.length; i++) {
                if (i < 5)
                    arts.push(res.articles[i])
                if (i < 10)
                    arts2.push(res.articles[i])
            }
            setArticlesRel(arts2);
            setArticles(arts);
        }
    };

    function generateHighlightedHTML(content, word) {
        var highlightedContent = content.replace(new RegExp(word, 'gi'), '<span class="highlighted">' + word + '</span>');
        return highlightedContent;
    }


    const [valueTab, setValueTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };
    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }


    const handleAudioPlayerOpen = () => {
        setShowAudioPlayer(true)
    }

    const handleAudioPlayerClose = () => {
        setShowAudioPlayer(false)
    }

    const handleOnChange = (key) => (value) => {
        setError({
            ...error,
            [key]: null
        });
        setDataCht({
            ...datacht,
            [key]: value
        });
    };

    const handleSaveChtGpt = async () => {

        if (userDetails) {
            if (userDetails.subscription) {
                const errors = {
                    searchPhrase: !searchPhrase,
                };
                setError(errors);
                let isFormValid = Object.values(errors).every((item) => !item);
                if (!isFormValid) return;
                setIsLoadingCht(true)
                const formData = new FormData();
                formData.append('appId', '29');
                formData.append('language', currentLang?.id);
                formData.append('searchphrase', btoa(unescape(encodeURIComponent(searchPhrase))))
                formData.append('subject', btoa(unescape(encodeURIComponent(`Place name : ${data?.articleName}, ${data?.tripName}.`))))
                formData.append('subjectId', tripId);
                formData.append('subSubjectId', articleId);
                const res = await fetchData(restAPIs.chatGPT(formData));
                if (res.statusCode === 200) {
                    setChatgptResponse({ answer: res?.answer, question: res?.question })
                    setIsLoadingCht(false)
                }
            }
            else {
                navigate('/subscribe')
            }
        }
        else {
            setShowLogin(true)
        }
    }


    const handleNavigateFAQ = () => {
        // userDetails ? userDetails?.subscription ? navigate(`/chatFAQ?subjectId=${tripId}&subSubjectId=${articleId}`) : navigate('/subscribe') : setShowLogin(true)
        userDetails ? userDetails?.subscription ? setShowFAQ(true) : navigate('/subscribe') : setShowLogin(true)
    }

    const handleCloseModelFAQ = () => {
        setShowFAQ(false)
    }


    const shareAnswer = (text) => {
        const url = window.location.href;
        if (navigator.share) {
            navigator.share({
                title: `${data?.tripName} \n\n ${text?.question}`,
                text: `${data?.tripName}:\n\n${text?.question}\n${text?.answer}\n\nRead more at: ${url}`,
            }).catch(error => console.error('Error sharing:', error));
        } else {
            alert('Sharing is not supported in your browser.');
        }
    }

    const copyToClipboard = (text) => {
        if (navigator.clipboard) {
            // Using the modern Clipboard API
            navigator.clipboard.writeText(text?.answer).then(() => {
                setErrorAlert({ msg: 'Answer copied to clipboard. You can now paste it anywhere.', key: Math.random(), open: true, severity: 'success' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            }).catch(err => {
                console.error('Failed to copy text: ', err);
                setErrorAlert({ msg: 'Failed to copy the answer. Please try again.', key: Math.random(), open: true, severity: 'error' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            });
        } else {
            // Fallback for older browsers
            const textArea = document.createElement('textarea');
            textArea.value = text?.answer;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                setErrorAlert({ msg: 'Answer copied to clipboard. You can now paste it anywhere.', key: Math.random(), open: true, severity: 'success' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            } catch (err) {
                console.error('Unable to copy text: ', err);
                setErrorAlert({ msg: 'Failed to copy the answer. Please try again.', key: Math.random(), open: true, severity: 'error' });
                setTimeout(() => {
                    setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                }, 3000);
            }
            document.body.removeChild(textArea);
        }
    };


    const [isGeneratingVideo, setIsGeneratingVideo] = useState(false);
    const [countdown, setCountdown] = useState(0);

    const handleGenerateVideo = async () => {
        setIsGeneratingVideo(true);
        const formData = new FormData();
        formData.append('script', data?.descriptionStriped.substring(0, 500));
        const res = await fetchData(restAPIs.generateVideo(formData));
        if (res.statusCode == 200 && res.operationId) {
            setTimeout(() => {
                setCountdown('10');
                generateVideo(res?.operationId);
            }, 30000);
        }
        else {
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 2000);
            setIsGeneratingVideo(false);
        }
    };


    const generateVideo = async (operationId) => {
        try {
            const formData = new FormData();
            formData.append('operationId', operationId);
            const checkStatus = async () => {
                const res = await fetchData(restAPIs.creatingVideo(formData));
                if (res.statusCode === 200) {
                    if (res.state === 'QUEUED' || res.state === 'PROCESSING') {
                        setErrorAlert({ msg: `Processing... ${res.progress}%`, key: Math.random(), open: true, severity: 'info' });
                        setCountdown(res.progress);
                        setTimeout(checkStatus, 10000); // Check again after 5 seconds
                    } else if (res.state === 'COMPLETE') {
                        setErrorAlert({ msg: 'Video generated successfully!', key: Math.random(), open: true, severity: 'success' });
                        setTimeout(() => {
                            setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
                        }, 2000);

                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = res.videoURL;
                        a.target = `_blank`;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(res.videoURL);
                        setIsGeneratingVideo(false);
                    } else {
                        setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
                        setIsGeneratingVideo(false);
                    }
                } else {
                    setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
                    setIsGeneratingVideo(false);
                }
            };
            checkStatus();
        } catch (error) {
            setErrorAlert({ msg: 'Failed to generate video.', key: Math.random(), open: true, severity: 'error' });
            setIsGeneratingVideo(false);
        }
    };


    return (
        <>
            <div className='container'>
                <div className='__banner_set pb-4' style={{ position: 'relative' }}>
                    <SliderBanner
                        imageArray={imageArray}
                    />
                    <div className='__gradient_art'></div>
                    <button className="__button_art" style={{ left: '2%' }} title='Previous Article' onClick={() => handleOnClickArticle(data?.prevArticleId)}>
                        <ArrowRightAltIcon style={{ rotate: '180deg' }} sx={{ fontSize: 50 }} /> <span>Previous Article</span>
                    </button>
                    <button className="__button_art" style={{ right: '2%' }} title='Next Article' onClick={() => handleOnClickArticle(data?.nextArticleId)}>
                        <span>Next Article</span> <ArrowRightAltIcon sx={{ fontSize: 50 }} />
                    </button>
                </div>


                <div className='__detail_content_wrapper'>
                    <div className='__main_content'>
                        <div className='__fab_set'>

                            {
                                data?.isOwner ? <Fab style={{ backgroundColor: '#8e8c8c', marginLeft: '10px', color: '#fff', zIndex: 0 }} size='small' aria-label="Edit Article" onClick={handleEditArticle}>
                                    <EditIcon />
                                </Fab> : ''}

                            {audioFiles.length > 0 &&
                                <Fab style={{ backgroundColor: '#dc3545', marginLeft: '10px', color: '#fff', zIndex: 0 }} size='small' onClick={handleAudioPlayerOpen}>
                                    <VolumeUpIcon />
                                </Fab>
                            }

                            {data?.contentLanguage === 'en' ? speaking ?
                                <Fab style={{ backgroundColor: '#ff9800', marginLeft: '10px', color: '#fff', zIndex: 0 }} size='small' title='Stop' aria-label="Speech" onClick={cancel}>
                                    <VolumeMuteIcon />
                                </Fab> :
                                <Fab style={{ backgroundColor: '#ff9800', marginLeft: '10px', color: '#fff', zIndex: 0 }} size='small' title='Start' aria-label="Speech" onClick={() => handleSpeak()}>
                                    <SpatialAudioOffIcon />
                                </Fab>
                                : ''
                            }

                            <Fab
                                style={{ backgroundColor: '#0780bd', marginLeft: '10px', color: '#fff', zIndex: 0 }} size='small'
                                aria-controls={`basic-menu-language`}
                                aria-haspopup="true"
                                aria-expanded={openMenuLang}
                                onClick={(e) => handleOpenLanguage(e)}>
                                <TranslateIcon />
                            </Fab>


                            <Menu
                                id={`basic-menu-language`}
                                anchorEl={anchorElMenuLang}
                                open={openMenuLang}
                                onClose={handleCloseMenuLang}
                                style={{ height: '320px' }}
                            >
                                <MenuItem onClick={() => handleLangClick('default')}>Default</MenuItem>
                                <MenuItem onClick={() => handleLangClick('af')}>Afrikaans</MenuItem>
                                <MenuItem onClick={() => handleLangClick('sq')}>Albanian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('am')}>Amharic</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ar')}>Arabic</MenuItem>
                                <MenuItem onClick={() => handleLangClick('hy')}>Armenian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('as')}>Assamese</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ay')}>Aymara</MenuItem>
                                <MenuItem onClick={() => handleLangClick('az')}>Azerbaijani</MenuItem>
                                <MenuItem onClick={() => handleLangClick('bm')}>Bambara</MenuItem>
                                <MenuItem onClick={() => handleLangClick('eu')}>Basque</MenuItem>
                                <MenuItem onClick={() => handleLangClick('be')}>Belarusian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('bn')}>Bengali</MenuItem>
                                <MenuItem onClick={() => handleLangClick('bho')}>Bhojpuri</MenuItem>
                                <MenuItem onClick={() => handleLangClick('bs')}>Bosnian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('bg')}>Bulgarian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ca')}>Catalan</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ceb')}>Cebuano</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ny')}>Chichewa</MenuItem>
                                <MenuItem onClick={() => handleLangClick('zh-CN')}>Chinese</MenuItem>
                                <MenuItem onClick={() => handleLangClick('co')}>Corsican</MenuItem>
                                <MenuItem onClick={() => handleLangClick('hr')}>Croatian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('cs')}>Czech</MenuItem>
                                <MenuItem onClick={() => handleLangClick('da')}>Danish</MenuItem>
                                <MenuItem onClick={() => handleLangClick('dv')}>Dhivehi</MenuItem>
                                <MenuItem onClick={() => handleLangClick('doi')}>Dogri</MenuItem>
                                <MenuItem onClick={() => handleLangClick('nl')}>Dutch</MenuItem>
                                <MenuItem onClick={() => handleLangClick('en')}>English</MenuItem>
                                <MenuItem onClick={() => handleLangClick('eo')}>Esperanto</MenuItem>
                                <MenuItem onClick={() => handleLangClick('et')}>Estonian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ee')}>Ewe</MenuItem>
                                <MenuItem onClick={() => handleLangClick('tl')}>Filipino</MenuItem>
                                <MenuItem onClick={() => handleLangClick('fi')}>Finnish</MenuItem>
                                <MenuItem onClick={() => handleLangClick('fr')}>French</MenuItem>
                                <MenuItem onClick={() => handleLangClick('fy')}>Frisian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('gl')}>Galician</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ka')}>Georgian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('de')}>German</MenuItem>
                                <MenuItem onClick={() => handleLangClick('el')}>Greek</MenuItem>
                                <MenuItem onClick={() => handleLangClick('gn')}>Guarani</MenuItem>
                                <MenuItem onClick={() => handleLangClick('gu')}>Gujarati</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ht')}>Haitian Creole</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ha')}>Hausa</MenuItem>
                                <MenuItem onClick={() => handleLangClick('haw')}>Hawaiian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('iw')}>Hebrew</MenuItem>
                                <MenuItem onClick={() => handleLangClick('hi')}>Hindi</MenuItem>
                                <MenuItem onClick={() => handleLangClick('hmn')}>Hmong</MenuItem>
                                <MenuItem onClick={() => handleLangClick('hu')}>Hungarian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('is')}>Icelandic</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ig')}>Igbo</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ilo')}>Ilocano</MenuItem>
                                <MenuItem onClick={() => handleLangClick('id')}>Indonesian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ga')}>Irish</MenuItem>
                                <MenuItem onClick={() => handleLangClick('it')}>Italian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ja')}>Japanese</MenuItem>
                                <MenuItem onClick={() => handleLangClick('jw')}>Javanese</MenuItem>
                                <MenuItem onClick={() => handleLangClick('kn')}>Kannada</MenuItem>
                                <MenuItem onClick={() => handleLangClick('kk')}>Kazakh</MenuItem>
                                <MenuItem onClick={() => handleLangClick('km')}>Khmer</MenuItem>
                                <MenuItem onClick={() => handleLangClick('rw')}>Kinyarwanda</MenuItem>
                                <MenuItem onClick={() => handleLangClick('gom')}>Konkani</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ko')}>Korean</MenuItem>
                                <MenuItem onClick={() => handleLangClick('kri')}>Krio</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ku')}>Kurdish (Kurmanji)</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ckb')}>Kurdish (Sorani)</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ky')}>Kyrgyz</MenuItem>
                                <MenuItem onClick={() => handleLangClick('lo')}>Lao</MenuItem>
                                <MenuItem onClick={() => handleLangClick('la')}>Latin</MenuItem>
                                <MenuItem onClick={() => handleLangClick('lv')}>Latvian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ln')}>Lingala</MenuItem>
                                <MenuItem onClick={() => handleLangClick('lt')}>Lithuanian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('lg')}>Luganda</MenuItem>
                                <MenuItem onClick={() => handleLangClick('lb')}>Luxembourgish</MenuItem>
                                <MenuItem onClick={() => handleLangClick('mk')}>Macedonian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('mai')}>Maithili</MenuItem>
                                <MenuItem onClick={() => handleLangClick('mg')}>Malagasy</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ms')}>Malay</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ml')}>Malayalam</MenuItem>
                                <MenuItem onClick={() => handleLangClick('mt')}>Maltese</MenuItem>
                                <MenuItem onClick={() => handleLangClick('mi')}>Maori</MenuItem>
                                <MenuItem onClick={() => handleLangClick('mr')}>Marathi</MenuItem>
                                <MenuItem onClick={() => handleLangClick('mni-Mtei')}>Meiteilon (Manipuri)</MenuItem>
                                <MenuItem onClick={() => handleLangClick('lus')}>Mizo</MenuItem>
                                <MenuItem onClick={() => handleLangClick('mn')}>Mongolian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('my')}>Myanmar (Burmese)</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ne')}>Nepali</MenuItem>
                                <MenuItem onClick={() => handleLangClick('no')}>Norwegian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('or')}>Odia (Oriya)</MenuItem>
                                <MenuItem onClick={() => handleLangClick('om')}>Oromo</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ps')}>Pashto</MenuItem>
                                <MenuItem onClick={() => handleLangClick('fa')}>Persian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('pl')}>Polish</MenuItem>
                                <MenuItem onClick={() => handleLangClick('pt')}>Portuguese</MenuItem>
                                <MenuItem onClick={() => handleLangClick('pa')}>Punjabi</MenuItem>
                                <MenuItem onClick={() => handleLangClick('qu')}>Quechua</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ro')}>Romanian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ru')}>Russian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('sm')}>Samoan</MenuItem>
                                <MenuItem onClick={() => handleLangClick('sa')}>Sanskrit</MenuItem>
                                <MenuItem onClick={() => handleLangClick('gd')}>Scots Gaelic</MenuItem>
                                <MenuItem onClick={() => handleLangClick('nso')}>Sepedi</MenuItem>
                                <MenuItem onClick={() => handleLangClick('sr')}>Serbian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('st')}>Sesotho</MenuItem>
                                <MenuItem onClick={() => handleLangClick('sn')}>Shona</MenuItem>
                                <MenuItem onClick={() => handleLangClick('sd')}>Sindhi</MenuItem>
                                <MenuItem onClick={() => handleLangClick('si')}>Sinhala</MenuItem>
                                <MenuItem onClick={() => handleLangClick('sk')}>Slovak</MenuItem>
                                <MenuItem onClick={() => handleLangClick('sl')}>Slovenian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('so')}>Somali</MenuItem>
                                <MenuItem onClick={() => handleLangClick('es')}>Spanish</MenuItem>
                                <MenuItem onClick={() => handleLangClick('su')}>Sundanese</MenuItem>
                                <MenuItem onClick={() => handleLangClick('sw')}>Swahili</MenuItem>
                                <MenuItem onClick={() => handleLangClick('sv')}>Swedish</MenuItem>
                                <MenuItem onClick={() => handleLangClick('tg')}>Tajik</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ta')}>Tamil</MenuItem>
                                <MenuItem onClick={() => handleLangClick('tt')}>Tatar</MenuItem>
                                <MenuItem onClick={() => handleLangClick('te')}>Telugu</MenuItem>
                                <MenuItem onClick={() => handleLangClick('th')}>Thai</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ti')}>Tigrinya</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ts')}>Tsonga</MenuItem>
                                <MenuItem onClick={() => handleLangClick('tr')}>Turkish</MenuItem>
                                <MenuItem onClick={() => handleLangClick('tk')}>Turkmen</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ak')}>Twi</MenuItem>
                                <MenuItem onClick={() => handleLangClick('uk')}>Ukrainian</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ur')}>Urdu</MenuItem>
                                <MenuItem onClick={() => handleLangClick('ug')}>Uyghur</MenuItem>
                                <MenuItem onClick={() => handleLangClick('uz')}>Uzbek</MenuItem>
                                <MenuItem onClick={() => handleLangClick('vi')}>Vietnamese</MenuItem>
                                <MenuItem onClick={() => handleLangClick('cy')}>Welsh</MenuItem>
                                <MenuItem onClick={() => handleLangClick('xh')}>Xhosa</MenuItem>
                                <MenuItem onClick={() => handleLangClick('yi')}>Yiddish</MenuItem>
                                <MenuItem onClick={() => handleLangClick('yo')}>Yoruba</MenuItem>
                                <MenuItem onClick={() => handleLangClick('zu')}>Zulu</MenuItem>

                            </Menu>


                            <Fab style={{ backgroundColor: '#fff', marginLeft: '10px', color: '#000', zIndex: 0 }} size='small' aria-label="Zodiac" onClick={() => handleOpenZodiac()}>
                                <StarsOutlinedIcon />
                            </Fab>

                            <Zodiac open={openZodiac} onClose={handleCloseZodiac} date={data && data.date} />
                            <Fab style={{ backgroundColor: '#fff', marginLeft: '10px', color: '#000', zIndex: 0, overflow: 'hidden' }} title='View trip' size='small' aria-label="Zodiac" onClick={() => handleGotoTrip()}>
                                <img src={data?.tripImage} alt={data?.tripName} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </Fab>

                            <Fab style={{ backgroundColor: '#2c5540', marginLeft: '10px', color: '#fff', zIndex: 0 }} size='small' aria-label="Zodiac" onClick={() => handleOpenShareSocial()}>
                                <ShareIcon />
                            </Fab>
                            <SocialShare
                                open={openShareSocial}
                                onClose={handleCloseShareSocial}
                                title={data?.articleName}
                                description={data?.description}
                                image={data?.coverImage}
                                links={urls}
                                url={window.location.href}
                                summary={summary}
                            />

                        </div>
                        <h1><Highlight text={data?.articleName ? data.articleName : ''} highlight={highlight} /></h1>
                        <p>{data?.displayDate}</p>

                        <div >
                            <Tabs value={valueTab} onChange={handleChangeTab} variant="fullWidth" >
                                <Tab label={t('about')} {...a11yProps(0)} />
                                <Tab label={t('media')} {...a11yProps(1)} />
                                <Tab label={t('map')} {...a11yProps(2)} />
                            </Tabs>

                            <div className=''>
                                <div
                                    role="tabpanel"
                                    hidden={valueTab !== 0}
                                    id={`simple-tabpanel-0`}
                                    aria-labelledby={`simple-tab-0`}
                                >
                                    <div className='mt-3'>
                                        <p className='__para' dangerouslySetInnerHTML={{ __html: description && description }}></p>
                                        {urls.length > 0 ? <h2 ref={linksRef}>{t('links')}</h2> : ''}
                                        {
                                            urls && urls.map((item, idx) => {
                                                return (
                                                    <p className='d-flex align-items-center'>
                                                        <a href={item} target="_blank" style={{ fontSize: '18px' }} ><ArrowRightIcon style={{ margin: '0 10px' }} />{item}</a>
                                                    </p>
                                                )
                                            })}

                                        <div className="generate-video-section">
                                            <Button
                                                label="Generate Video"
                                                handleOnClick={handleGenerateVideo}
                                                disabled={isGeneratingVideo}
                                                style={{ margin: 0, backgroundColor: '#2c5540', color: '#fff', height: '40px', minWidth: '100px' }}
                                            />
                                            {isGeneratingVideo && (
                                                <div className="countdown-timer">Generating video in few seconds ({countdown}%)</div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div
                                    role="tabpanel"
                                    hidden={valueTab !== 1}
                                    id={`simple-tabpanel-1`}
                                    aria-labelledby={`simple-tab-1`}
                                >
                                    <div className='mt-3'>

                                        {videos && videos.length > 0 ? <h2>{t('videos')}</h2> : ''}
                                        <div className='__videos-list mt-3'>
                                            {videos && videos.map((item, idx) => {
                                                return (
                                                    <div className='player-wrapper'>
                                                        <ReactPlayer url={item} controls={true}
                                                            className='react-player'
                                                            width='100%'
                                                            height='100%'
                                                        />
                                                    </div>
                                                )
                                            })}
                                            <div></div>
                                            <div></div>
                                        </div>

                                        {assets && assets.length > 0 ? <h2 ref={assetsRef}>{t('photos')}</h2> : ''}
                                        <PhotoProvider>
                                            <div className='__photos-list mt-3 mb-2'>

                                                {assets && assets.map((item, idx) => {
                                                    return (
                                                        <PhotoView src={item} >
                                                            <img src={item} alt="" key={idx} />
                                                        </PhotoView>
                                                    )
                                                })}
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </PhotoProvider>
                                    </div>
                                </div>
                                <div
                                    role="tabpanel"
                                    hidden={valueTab !== 2}
                                    id={`simple-tabpanel-2`}
                                    aria-labelledby={`simple-tab-2`}
                                >
                                    <div className='mt-3'>
                                        <iframe src={mapUrl} width="100%" height="270" frameborder="0"
                                            style={{
                                                border: 0,
                                                borderRadius: '20px',
                                                boxShadow: '1px 1px 10px #0003',
                                                margin: '15px 0',
                                                padding: '5px'
                                            }}
                                        ></iframe>
                                    </div>
                                </div>
                            </div>

                            <div className="_cht_full">
                                <h4 className="__chat_heading">{t('Ask_about_this_book_and_get_your_answers_instantly')}</h4>
                                <div className="__input_gpt">
                                    <InputField
                                        type="text"
                                        placeholder={t('ask_anything')}
                                        onChange={handleOnChange('searchPhrase')}
                                        error={error.searchPhrase}
                                        value={searchPhrase}
                                        name='searchPhrase'
                                    />

                                    <Button
                                        label={t('send')}
                                        icon={<EastOutlinedIcon />}
                                        handleOnClick={handleSaveChtGpt}
                                        withLoader
                                        loading={isLoadingCht}
                                        disabled={isLoadingCht}
                                    />

                                </div>
                                <p dir={currentLang?.id != 1 ? 'rtl' : 'ltr'}> <a className='__faq' onClick={() => handleNavigateFAQ()}><img src={QA} style={{ width: '22px' }} />{t('frequently_asked_questions')}</a></p>
                                <div className="__resp_gpt" >
                                    {chatgptResponse && <h3>{chatgptResponse?.question}</h3>}
                                    {chatgptResponse && <p dangerouslySetInnerHTML={{ __html: chatgptResponse?.answer }}></p>}
                                    {chatgptResponse && <div className='__buttons'>
                                        <Button
                                            title={t('share')}
                                            label={<ShareIcon />}
                                            handleOnClick={() => shareAnswer(chatgptResponse)}
                                        />
                                        <Button
                                            title={t('Copy')}
                                            label={<FileCopy />}
                                            handleOnClick={() => copyToClipboard(chatgptResponse)}
                                        />
                                    </div>}
                                </div>
                            </div>
                        </div>



                        <h2 className='mb-3 pt-3' ref={commentsRef}>{t('comments')} ({comments && comments.length})</h2>
                        {comments && comments.length == 0 ? 'No Comments' : ''}
                        {
                            comments && comments?.map((item, idx) => {
                                return (
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Comment
                                            key={idx}
                                            image={item.profilePic}
                                            title={item.fullName}
                                            decription={item.comment}
                                            time={item.commentDate}
                                        />
                                        {item.isOwner ?
                                            <div key={idx + item.commentId} className='__comment_dot_icon'>
                                                <IconButton
                                                    aria-controls={openMenu ? `basic-menu${item.commentId}` : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openMenu ? 'true' : undefined}
                                                    onClick={(e) => handleClickMenu(e, idx)}>
                                                    <MoreVertIcon />
                                                </IconButton>

                                                <Menu
                                                    id={`basic-menu${item.commentId}`}
                                                    anchorEl={anchorElMenu}
                                                    open={openMenu === idx}
                                                    onClose={handleCloseMenu}
                                                    className="__comment_edit_menu"
                                                >
                                                    <MenuItem key={idx + 5} onClick={() => handleEditCommentClick(idx)}>{t('edit')}</MenuItem>
                                                    <EditComment
                                                        key={idx}
                                                        open={openEditComment === idx}
                                                        onClose={handleCloseEditComment}
                                                        commentData={item.comment}
                                                        tripId={tripId}
                                                        isArticle={true}
                                                        commentId={item.commentId}
                                                        articleId={articleId}
                                                    />
                                                    <MenuItem key={idx + 10} onClick={() => handleDeleteCommentClick()}>{t('delete')}</MenuItem>
                                                    <Dialog
                                                        open={openDeleteComment}
                                                        onClose={handleCloseDeleteComment}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby="alert-dialog-description"
                                                    >
                                                        <DialogTitle id="alert-dialog-title">
                                                            {t('delete')}
                                                        </DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-description">
                                                                Are you sure you want to remove this comment !
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <a onClick={() => handleCloseDeleteComment(false)} style={{ cursor: 'pointer' }}>{t('cancel')}</a>
                                                            <a onClick={() => handleCloseDeleteComment(true, item.commentId)} style={{ margin: '0 15px', fontWeight: 'bold', marginLeft: '30px', cursor: 'pointer' }}>{t('ok')}</a>
                                                        </DialogActions>
                                                    </Dialog>
                                                </Menu>
                                            </div> : ''}
                                    </div>
                                )
                            })
                        }
                        <div className='__add_comment_wrap'>
                            <span>{t('read_smtg_u_like')}</span>
                            {
                                userDetails ?
                                    <div className='__comment_adding'>
                                        <img src={userDetails?.profile_pic} />
                                        <div>
                                            <TextArea
                                                type="text"
                                                placeholder={t('your_comment')}
                                                height={110}
                                                value={comment}
                                                onChange={handleOnChangeComment('comment')}
                                                error={errorComment.comment}
                                            />
                                            <Button
                                                // label={t('submit')}
                                                icon={<SendIcon />}
                                                className='__new_submit_btn'
                                                handleOnClick={handleSubmitComment}
                                                withLoader
                                                loading={isLoadingComment}
                                                disabled={isLoadingComment}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <Button
                                            label={t('write_a_comment')}
                                            className='__marg_'
                                            handleOnClick={handleOpenLoginModel}
                                        />
                                    </>

                            }
                        </div>
                    </div>
                    <div className='__related_content'>
                        <div className='mb-5'>
                            <PremiumWidget />
                        </div>
                        {articlesRel.length > 0 ?
                            <h2>{t('related_articles')}</h2> : ''}
                        {
                            articlesRel && articlesRel?.map((item, idx) => {
                                return (
                                    idx % 3 ?
                                        <div style={{ position: 'relative' }}>
                                            <div className='__related_item' onClick={() => handleOnClickArticle(item.articleId)}>
                                                <img src={item.coverImage} />
                                                <div>
                                                    <h1>{item.articleName}</h1>
                                                    <p>{item.displayDate}</p>
                                                </div>
                                            </div>
                                        </div> : ''
                                )
                            })
                        }


                    </div>
                </div>
                {articles.length > 0 ? <h2 style={{ marginBottom: '0px' }} className='mt-5'>More Articles on this trip {data?.tripName}</h2> : ''}
                <div className='__detail_content_wrapper' style={{ marginTop: '10px' }}>
                    <div className='__article-list'>
                        {isLoadingArticlre ?
                            [...Array(3)].map((item, idx) => {
                                return (
                                    <Skelton />
                                )
                            })
                            :
                            articles && articles?.map((item, idx) => {
                                return (
                                    <div style={{ position: 'relative' }}>
                                        <Article
                                            title={item.articleName}
                                            para={item.displayDate}
                                            image={item.coverImage}
                                            handleOnClick={() => handleOnClickArticle(item.articleId)}
                                        />
                                    </div>



                                )
                            })
                        }


                        <div></div><div></div><div></div>

                    </div>
                </div>
            </div>

            {errorAlert && errorAlert.open ? <Snackbar open={errorAlert.open} autoHideDuration={3000} >
                <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
                    {errorAlert.msg}
                </Alert>
            </Snackbar> : ''}

            <Login
                open={showLogin}
                onClose={handleCloseLoginModel}
            />

            <AudioPlayerModal
                open={showAudioPlayer}
                onClose={handleAudioPlayerClose}
                files={audioFiles}
            />

            <FAQ
                open={showFAQ}
                onClose={handleCloseModelFAQ}
                subjectId={tripId}
                subSubjectId={articleId}
            />

        </>
    );
};

export default ViewArticle;
