// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './styles.scss';

import TimeAgo from 'react-timeago'

const Comment = (props) => {
    const { title, decription, image,time, handleOnClick } = props;
    return (
        <div className='__comment_wrap'>
            <img src={image} alt={title}/>
            <div style={{margin:'0 15px'}}>
                <h1>{title} <span> <TimeAgo date={time} /></span></h1>
                <p>{decription}</p>
            </div>
        </div>

    );
};

export default Comment;

Comment.propTypes = {
    title: PropTypes.string,
    decription: PropTypes.string,
    image: PropTypes.string,
    time: PropTypes.string,
    handleOnClick: PropTypes.func
};
