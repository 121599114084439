import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import './style.scss';

const PrivacyPolicy = (props) => {
  const { id } = props;
  const { currentLang } = useSelector((store) => store.commonData);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const fetchContent = async () => {
    setLoading(true)
    const res = await fetchData(restAPIs.getEVPages(currentLang?.id, id));
    setData(res.page);
    setLoading(false)
  };


  useEffect(() => {
    fetchContent();
  }, [currentLang, id]);
  return (
    <div className="container">
      <div className="static-page">
        <div className="privacy">
          {isLoading ?
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}

            >
              <CircularProgress color="inherit" />
            </Backdrop>
            :
            <>
              <h1 className='__common_page_head'>{data?.name}</h1>
              <p dangerouslySetInnerHTML={{ __html: data?.description }}></p></>}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
