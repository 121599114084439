// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './styles.scss';
import { MenuItem } from '@mui/material';
import {Select as Selected} from '@mui/material';

const Select = (props) => {
    const { label, name, placeholder, selectedvalue, handleChange, options, error, ...rest } = props;
    return (
        <div className={error? '__select __error':'__select'} >
            {label && <label>{label}</label>}
            <Selected
                value={selectedvalue}
                onChange={handleChange}
                displayEmpty
            >
                {options.map(option => (
                    <MenuItem key={+option.value} value={option.value}>{option.text}</MenuItem>
                ))}
            </Selected>

        </div>
    );
};

export default Select;

Select.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    selectedvalue: PropTypes.any,
    options: PropTypes.any,
    handleChange: PropTypes.func,
    error: PropTypes.any,
};
