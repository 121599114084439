// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import Download from 'components/commonComponents/DownloadApp';
import Banner from 'components/commonComponents/Banner';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const HelpAndSupport = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [expanded, setExpanded] = useState('panel1');
    const [supports, setSupports] = useState([])

    const handleChange = (panel) =>  {
      setExpanded(panel);
    };

    const fetchSupport = async () => {
        const res = await fetchData(restAPIs.helpAndSupport(currentLang?.id));
        if (res.statusCode === 200) {
            setData(res)
            setSupports(res.supports)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchSupport();;
    }, [currentLang]);


    return (
        <>
            <Banner
                title={t('help_support')}
                image={BannerImg}
                withSearch={false}
            />
            <div className='container mt-5'>
                <div className='row'>
                    <div className='col-md-3 mb-5'>
                        <img src={data?.applicationIcon} alt="" width={'100%'} height={'200px'} style={{ objectFit: 'contain' }} />
                    </div>
                    <div className='col-md-9 mb-5'>
                        <h4 style={{ color: '#4ac3f5', margin: 0 }}>{data?.tagline}</h4>
                        <h1 className='__common_page_head' style={{ color: '#439cc8', margin: 0, fontSize: '3rem' }}>{data?.name}</h1>
                        <p dangerouslySetInnerHTML={{ __html: data?.description }} style={{ lineHeight: '2', fontSize: '17px' }}></p>
                        <div className='row justify-content-center'>
                            <div className='col-md-8'>
                                <div className='player-wrapper'>
                                    <ReactPlayer url={data?.videoURL} controls={true}
                                        className='react-player'
                                        width='100%'
                                        height='100%'
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='mt-4' style={{padding:'10px'}} dir={currentLang?.id==1?'rtl':'ltr'}>
                            {supports && supports.map((item, idx) => {
                                return (
                                    <Accordion className='mb-2'>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography>
                                                {item?.name}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography dangerouslySetInnerHTML={{ __html: item?.description }}>
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                        </div>

                        
                    </div>
                </div>

                <Download />
            </div>
        </>
    );
};

export default HelpAndSupport;
