// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { InputWrapper } from './style';

const TextArea = (props) => {
  const { type, placeholder, onChange, value = '', label, error, onKeyDown, height } = props;
  const onChangeHandle = (e) => onChange?.(e.target.value);
  return (
    
    <InputWrapper error={error} height={height} className='__arb'>
      {label && <label>{label}</label>}
      <textarea
        autoComplete="off"
        value={value}
        placeholder={placeholder}
        onChange={onChangeHandle}
        type={type}
        onKeyDown={onKeyDown}
      />
      {!!error && <p>{error}</p>}
    </InputWrapper>
  );
};

export default TextArea;

TextArea.propTypes = {
  label: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  height: PropTypes.any,
};
