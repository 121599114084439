// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { StyledButton } from './style';
import { CircularProgress } from '@mui/material';

import './styles.scss'

const Button = (props) => {
  const { theme, label, withLoader, loading, handleOnClick, icon, ...rest } = props;
  return (
    <StyledButton {...rest} onClick={handleOnClick} buttonStyle={theme}>
      {withLoader && loading ? <CircularProgress color="inherit" size={30}/> : label}
      {withLoader && loading ? '' : icon ? <span className='__ico'>{icon}</span> : ''}
    </StyledButton>
  );
};

export default Button;

Button.propTypes = {
  theme: PropTypes.string,
  label: PropTypes.string,
  withLoader: PropTypes.bool,
  loading: PropTypes.bool,
  handleOnClick: PropTypes.func,
  icon: PropTypes.any
};
