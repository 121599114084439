// Core
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WeiboShareButton,
  WorkplaceShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";

// Styles
import './styles.scss';
import Button from 'components/commonComponents/Button';


const SocialShare = (props) => {
  const { onClose, open, title, url, description, links, image, summary } = props;
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        maxWidth={'sm'}
        fullWidth={true}
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-title">
        {t('share')}
        </DialogTitle>
        <DialogContent>
          <div className='mt-2'>

            <div style={{
              display: 'grid', gridGap: '10px',
              gridTemplateColumns: 'repeat(auto-fit, minmax(52px, 1fr))'
            }}>
              <FacebookShareButton
                quote={summary}
                url={url}
                hashtag="#hashtag1"
              ><FacebookIcon size={42} round /></FacebookShareButton>

              <WhatsappShareButton
                title={summary}
                url={url}
                separator=" "
              ><WhatsappIcon size={42} round /></WhatsappShareButton>
              <LineShareButton
                url={url}
                title={summary}
              >
                <LineIcon size={42} round />
              </LineShareButton>
              <PinterestShareButton
                url={url}
                media={image}
                description={summary}
              >
                <PinterestIcon size={42} round />
              </PinterestShareButton>

              <RedditShareButton
                url={url}
                title={title}
              >
                <RedditIcon size={42} round />
              </RedditShareButton>

              <TelegramShareButton
                url={url}
                title={title}
              >
                <TelegramIcon size={42} round />
              </TelegramShareButton>

              <TumblrShareButton
                url={url}
                title={title}
                caption={summary}
              >
                <TumblrIcon size={42} round />
              </TumblrShareButton>

              <TwitterShareButton
                url={url}
                title={title}
              >
                <TwitterIcon size={42} round />
              </TwitterShareButton>

              <ViberShareButton
                url={url}
                title={title}
              >
                <ViberIcon size={42} round />
              </ViberShareButton>
              <LinkedinShareButton url={url}>
                <LinkedinIcon size={42} round />
              </LinkedinShareButton>

              <VKShareButton
                url={url}
                image={image}
                title={title}
              >
                <VKIcon size={42} round />
              </VKShareButton>
              <MailruShareButton
                url={url}
                title={title}
              >
                <MailruIcon size={42} round />
              </MailruShareButton>

              <LivejournalShareButton
                url={url}
                title={title}
                description={summary}
              >
                <LivejournalIcon size={42} round />
              </LivejournalShareButton>

              <WorkplaceShareButton
                url={url}
                quote={summary}
              >
                <WorkplaceIcon size={42} round />
              </WorkplaceShareButton>

              <PocketShareButton
                url={url}
                title={title}
              >
                <PocketIcon size={42} round />
              </PocketShareButton>

              <InstapaperShareButton
                url={url}
                title={title}
              >
                <InstapaperIcon size={42} round />
              </InstapaperShareButton>

              <HatenaShareButton
                url={url}
                title={title}
              >
                <HatenaIcon size={42} round />
              </HatenaShareButton>

              <EmailShareButton
                url={url}
                subject={title}
                body={summary}
              >
                <EmailIcon size={42} round />
              </EmailShareButton>

              <WeiboShareButton
                url={url}
                title={title}
                image={image}
              >
                <WeiboIcon size={42} round />
              </WeiboShareButton>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>

        </DialogContent>
        <DialogActions>
          <div className='d-flex align-items-center' style={{ margin: '15px' }}>
            <Button
              handleOnClick={handleClose}
              label={t('cancel')}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SocialShare;

SocialShare.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.any,
  url: PropTypes.any,
  description: PropTypes.any,
  links: PropTypes.any,
  image: PropTypes.any,
  summary: PropTypes.any
};
