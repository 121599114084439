// Core
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// components
import Banner from 'components/commonComponents/Banner';

// Styles
import './styles.scss';

// Assets
import BannerImg from 'assets/images/banner.webp'


import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Founder = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const fetchResult = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getFounderInfo(currentLang?.id));
        setData(res.founder);
        setLoading(false)
    };

    useEffect(() => {
        fetchResult();
    }, [currentLang]);

    return (
        <>
            <Banner
                title='The Founder'
                placeholder='Search for destination, places or trips'
                image={BannerImg}
                withSearch={false}
            />

            <div className='container mt-5' style={{ minHeight: '50vh' }}>
                {
                    isLoading ?
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        :
                        <>
                            <div className='row justify-content-center'>
                                <div className='col-md-3 mb-3'>
                                    <img src={data && data[0]?.logo} alt={data && data[0]?.name} width={'100%'} height={'200px'} style={{ objectFit: 'contain' }} />
                                </div>
                                {data && data?.map((item, idx) => {
                                    return (
                                        <div className='col-md-9 mb-3'>
                                            <h1 className='__common_page_head' style={{ color: '#4ac3f5', margin: 0 }}>{item?.name}</h1>
                                            <p dangerouslySetInnerHTML={{ __html: item?.description }} style={{ lineHeight: 1.7 }}></p>
                                        </div>
                                    )
                                })}

                            </div>
                        </>
                }
            </div>

        </>
    );
};

export default Founder;
