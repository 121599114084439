// Core
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import Login from 'components/modals/Login';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useEffect } from 'react';

import BannerImg from 'assets/images/banner.webp'
import Button from 'components/commonComponents/Button';

// icons
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Banner from 'components/commonComponents/Banner';
import Download from 'components/commonComponents/DownloadApp';
import Skelton from 'components/commonComponents/Skelton';
import InputField from 'components/commonComponents/InputField';

const Subscription = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const [data, setData] = useState('')
  const [isLoading, setLoading] = useState(false)

  const [referralCode, setreferralCode] = useState('')
  const [discountCode, setdiscountCode] = useState('')
  const [searchParams] = useSearchParams();

  const [dataSet, setDataSet] = useState({});
  const [error, setError] = useState('');
  const [isLoadingApply, setIsLoadingApply] = useState(false);
  const { code } = dataSet;
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })

  const handleOnChange = (key) => (_value) => {
    if (_value) {
      setError({
        ...error,
        [key]: null
      });
    }
    setDataSet({
      ...dataSet,
      [key]: _value
    });
  };

  const handleClick = (id) => {
    userDetails ? referralCode ? navigate('/subscription-payment/' + id + '?refcode=' + referralCode) : discountCode ? navigate('/subscription-payment/' + id + '?discountCode=' + discountCode) : navigate('/subscription-payment/' + id) : setShow(true)
  }

  const handleCloseModel = () => {
    setShow(false);
  };

  const fetchSubscriptions = async () => {
    setreferralCode(searchParams.get('refcode'))
    setdiscountCode(searchParams.get('discountCode'))
    setLoading(true)
    const formData = new FormData();
    formData.append('language', currentLang?.id)
    formData.append('appId', '29')
    if (searchParams.get('refcode'))
      formData.append('refCode', searchParams.get('refcode'))
    if (searchParams.get('discountCode'))
      formData.append('discountCode', searchParams.get('discountCode'))
    const res = await fetchData(restAPIs.getSubscriptions(formData));
    setData(res.info);
    setLoading(false)
  };

  useEffect(() => {
    setreferralCode(searchParams.get('refcode'))
    setdiscountCode(searchParams.get('discountCode'))
    fetchSubscriptions();
  }, [currentLang]);


  const handleSave = async () => {
    const errors = {
      code: !code,
    };

    setError(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (!isFormValid) return;
    setdiscountCode(code);
    setIsLoadingApply(true);
    setLoading(true);
    const formData = new FormData();
    formData.append('language', currentLang?.id);
    formData.append('appId', '29');
    formData.append('discountCode', code);
    const res = await fetchData(restAPIs.getSubscriptions(formData));
    setData(res.info);
    setError({});
    setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
    setTimeout(() => {
      setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
    }, 3000);
    setIsLoadingApply(false);
    setLoading(false);
  }

  return (
    <>
      <Banner
        title={t('subscription')}
        decription={t('downgrade_or_upgrade_at_any_time')}
        image={BannerImg}
        withSearch={false}
      />
      <div className='container mt-5'>
        {/* <h1 className='__common_page_head'>Subscription</h1> */}

        <div className='row justify-content-center mb-4'>
          <div className='col-md-4 d-flex flex-column' style={{gridGap:'10px'}}>
            <InputField
              onChange={handleOnChange('code')}
              value={code}
              placeholder={'Discount Code'}
              type='text'
              label={'Discount Code'}
              error={error.code}
            />
            <Button
              withLoader
              loading={isLoadingApply}
              disabled={isLoadingApply}
              handleOnClick={handleSave}
              label={t('submit')}
            />
          </div>
        </div>



        {isLoading ?
          <div className="row justify-content-center">
            {[...Array(2)].map((item, idx) => {
              return (
                <div className="col-md-4 mb-4">
                  <Skelton />
                </div>
              )
            })}
          </div> :
          <div className="row justify-content-center">
            {data && data.map((item, idx) => {
              return (
                <div className="col-md-4 mb-4" key={idx}>
                  <div className="__subBlock" style={{ border: item?.duration !== 30 ? '2px solid #31a3e0' : 'none' }}>
                    {item?.duration !== 30 ? <span className='recommend'>{t('recommended')}</span> : ''}
                    <h1>{item?.duration === 30 ? t('monthly') : t('yearly')} </h1>
                    <h2>AED {item?.price}.00</h2>
                    <p><CheckCircleIcon style={{ color: '#18B943', margin: '0 10px' }} />{item?.subscriptionName}</p>
                    <p><CheckCircleIcon style={{ color: '#18B943', margin: '0 10px' }} />{item?.descline1}</p>
                    <p><CheckCircleIcon style={{ color: '#18B943', margin: '0 10px' }} />{item?.descline2}</p>
                    <p><CheckCircleIcon style={{ color: '#18B943', margin: '0 10px' }} /><b>{item?.duration}</b> days</p>
                    <p><CheckCircleIcon style={{ color: '#18B943', margin: '0 10px' }} />AED {item?.price}.00</p>
                    <Button
                      label={item?.duration === 30 ? t('subscribe_monthly') : t('subscribe_yearly')}
                      icon={<EastOutlinedIcon />}
                      handleOnClick={() => handleClick(item?.subscriptionId)}
                      style={{ marginLeft: '0', width: '100%' }}
                      className='mt-4'
                    />
                  </div>
                </div>
              )
            })}

          </div>
        }
        <Login
          open={show}
          onClose={handleCloseModel}
        />
        <Download />
      </div>
    </>
  );
};

export default Subscription;